import React, { useEffect, useState } from "react";
import {
  Modal,
  Grid,
  Card,
  Typography,
  FormControl,
  InputLabel,
  FormLabel,
  RadioGroup,
  TextField,
  Button,
  FormControlLabel,
  Radio
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { LeaveApplications } from "../../../../../../api/Endpoints/LeaveApplication";
import moment from "moment";
import TextInput from "../../../../../form/TextInput";

const approvalStatusOptions = [
  { value: "not Reviewed", label: "Not Reviewed" },
  { value: "approved", label: "Approved" },
  { value: "rejected", label: "Rejected" },
  { value: "pending", label: "Pending" }
];

const ApproveLeaveModal = ({
  isOpen,
  setIsOpen,
  approveId,
  setApproveId,
  onUpdate,
  formValues
}) => {
  const [employee, setEmployee] = useState(null);
  useEffect(() => {
    if (approveId) {
      
      // Fetch data or perform any action with approveId if needed
    }
  }, [approveId]);

  const { control, watch, handleSubmit, setValue,reset } = useForm();
  const [formButtonStatus, setFormButtonStatus] = useState({
    label: "Submit",
    loading: false,
    disabled: false
  });
  const [alerts, setAlerts] = useState({
    type: "",
    message: "",
    active: false
  });

  const closeModal = () => {
    setIsOpen(false);
  };

  const fetchDetails = async () => {
    try {
      const res = await LeaveApplications.getDetails(approveId);
      setEmployee(res.data?.data);
    } catch (error) {
      
    }
  };
  // console.log("details",employee)
  // 
  const onSubmit = async (data) => {
    try {
      setFormButtonStatus({ ...formButtonStatus, loading: true });
      setAlerts({
        active: false,
        message: "Internal server error",
        type: "error"
      });

      // Prepare data for submission
      const dataToSubmit = {
        id: approveId,
        employee_user_id: formValues.employee_user_id,
        start_date: formValues.start_date,
        end_date: formValues.end_date,
        count: formValues.count,
        manager_id: formValues.manager_id,
        hr_id: formValues.hr_id,
        manager_approval_status: formValues.manager_approval_status,
        hr_approval_status: data.hr_approval_status,
        hr_review_date: moment(new Date()).format("YYYY-MM-DD"),
        hr_remarks: data.hr_remarks
      };

      let action;

      

      // If there's an approveId, perform an update
      if (approveId > 0) {
        action = LeaveApplications.update(approveId, dataToSubmit);
      } else {
        throw new Error("Invalid approval ID");
      }

      // Await the action and handle the response
      const response = await action;

      // Handle successful submission
      setFormButtonStatus({
        label: "Submitted",
        loading: false,
        disabled: true
      });

      setAlerts({
        active: true,
        message: response.data.message,
        type: response.data.status
      });

      setFormButtonStatus({
        label: "Create",
        loading: false,
        disabled: false
      });

      toast.success(response.data.message);
      reset()
      onUpdate();
      setApproveId(); // Reset approveId
      closeModal(); // Close the modal

      // Reset alerts after a short delay
      setTimeout(() => {
        setAlerts({});
      }, 2000);
    } catch (error) {
      // Handle errors during the process
      
      toast.error(error?.response?.data?.message || "Internal server error");

      setAlerts({
        active: true,
        message: error?.response?.data?.message || "Internal server error",
        type: "error"
      });

      setFormButtonStatus({
        label: "Create",
        loading: false,
        disabled: false
      });
    }
  };

  // useEffect(() => {
  // fetchDetails()
  // }, [approveId])
  useEffect(() => {
    
    // Debug full data if needed
  }, [approveId, formValues]);

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh", padding: 20 }}
      >
        <Card
          sx={{
            p: 4,
            width: "60%",
            maxWidth: "800px",
            bgcolor: "background.paper",
            boxShadow: 24
          }}
        >
          <Typography variant="h5" gutterBottom>
            Approve Leave
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth sx={{ pt: 2 }}>
                  <FormLabel>Hr Approval Status</FormLabel>
                  <Controller
                    name="hr_approval_status"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <RadioGroup {...field} sx={{ flexDirection: "row" }} defaultValue={formValues.hr_approval_status}>
                        {approvalStatusOptions.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>HR Remarks</InputLabel>
                <Controller
                  name="hr_remarks"
                  control={control}
                  defaultValue={formValues.hr_remarks || ""}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      multiline
                      fullWidth
                      rows={4}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="flex-end"
              spacing={2}
              sx={{ mt: 2 }}
            >
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="error" onClick={closeModal}>
                  Close
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Modal>
  );
};

export default ApproveLeaveModal;
