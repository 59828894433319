import React, { useState } from 'react';
import { Box, Button, Card, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, InputLabel, Slide, Typography } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import TextInput from '../../../form/TextInput'
import { complaintsAPI } from './staticData';
import { LoadingButton } from "@mui/lab";
import { toast } from 'react-toastify';
import { ErrorMessage } from "@hookform/error-message";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from 'react';
import '../../../../Custom.css'
import { DotSpinner } from '@uiball/loaders';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
    title: yup.string().required(),
    description: yup.string().required(),
});

const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isAnonymous, setIsAnonymous] = useState(false)

    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            props.setEditId(undefined)
        }, 100)
    };

    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true });

        let dataToSubmit = {
            id: props?.editId,
            title: data.title,
            description: data.description,
            is_anonymous: isAnonymous ? 1 : 0
        };

        let action;
        if (props.editId > 0) {
            action = complaintsAPI.update(dataToSubmit);
        } else {
            action = complaintsAPI.add(dataToSubmit);
        }

        action.then(response => {
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
            if (response.data.errors) {
                let err = response.data.errors
                Object.keys(err).forEach((key) => {
                    toast.error(err[key].name, { autoClose: 3000 });
                });
                return
            }

            toast.success(response.data.message, { autoClose: 3000 })
            props.onUpdate();
            setOpen(false)
            reset();
            setIsAnonymous(false);
        }).catch(errors => {
            toast.error("Internal server error", { autoClose: 3000 });
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
            setOpen(false)
        })
    }

    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchDetails()
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            setOpen(true);
            reset();
            setIsAnonymous(false);
        }
    }, [props.editId])

    const fetchDetails = async () => {
        setLoading(true)
        let complaintDetails = await complaintsAPI.getDetails({ id: props.editId });
        if (complaintDetails.data.status === "success") {
            let data = complaintDetails.data.data;
            setValue('title', data.title);
            setValue('description', data.description);
            setIsAnonymous(data.is_anonymous === 1);
            setLoading(false)
        }
    }

    return (
        <div>
            <Button
                className='create-btn'
                variant="contained"
                onClick={handleClickOpen}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <AddIcon sx={{ marginRight: '2px' }} />
                New Complaint
            </Button>
            <Dialog
                open={open}
                PaperProps={{ sx: { width: "40%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{parseInt(props.editId) > 0 ? "Edit Complaint" : "Add Complaint"}</DialogTitle>

                {loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }}>
                        <DotSpinner
                            size={47}
                            speed={0.9}
                            color="rgb(63,197,149"
                        />
                    </Box>
                ) : (
                    <>
                        <DialogContent sx={{ p: 2, mt: 3 }}>
                            <Grid container>
                                <Grid item xs={12} sm={12}>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <Grid container>
                                            <Grid sx={{ p: 1 }} item xs={12}>
                                                <TextInput 
                                                    control={control} 
                                                    name="title"
                                                    label="Title"
                                                    value={watch('title')} 
                                                />
                                            </Grid>
                                            <Grid sx={{ p: 1 }} item xs={12}>
                                                <TextInput 
                                                    control={control} 
                                                    name="description"
                                                    label="Description"
                                                    value={watch('description')}
                                                    isMultiline
                                                    row={5}
                                                />
                                            </Grid>
                                            <Grid sx={{ p: 1 }} item xs={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={isAnonymous}
                                                            onChange={(e) => setIsAnonymous(e.target.checked)}
                                                            name="is_anonymous"
                                                        />
                                                    }
                                                    label="Is Anonymous"
                                                />
                                            </Grid>
                                            <Grid sx={{ p: 1 }} item xs={12}>
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="multipleErrorInput"
                                                    render={({ messages }) =>
                                                        messages &&
                                                        Object.entries(messages).map(([type, message]) => (
                                                            <p key={type}>{message}</p>
                                                        ))
                                                    }
                                                />

                                                <LoadingButton 
                                                    loading={formButtonStatus.loading} 
                                                    disabled={formButtonStatus.disabled}
                                                    variant="outlined" 
                                                    color='success' 
                                                    type="submit"
                                                >
                                                    {formButtonStatus.label}
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} variant='outlined' color='error'>Close</Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </div>
    );
};

export default Create;