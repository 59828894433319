// Static data for complaints module

export const complaintsData = {
  data: [
    {
      id: 1,
      title: "Poor Internet Connection",
      description: "The internet connection in the office has been very slow for the past week, making it difficult to work efficiently.",
      created_at: "2023-05-15T10:30:00",
      is_anonymous: 0,
      resolution: null
    },
    {
      id: 2,
      title: "Broken Air Conditioning",
      description: "The air conditioning in the meeting room has stopped working. It's becoming uncomfortable to have meetings there.",
      created_at: "2023-05-20T14:45:00",
      is_anonymous: 1,
      resolution: "Maintenance team has been notified and will fix it by end of week."
    },
    {
      id: 3,
      title: "Cafeteria Food Quality",
      description: "The quality of food in the cafeteria has declined significantly in the last month. Many employees have complained about this issue.",
      created_at: "2023-06-02T09:15:00",
      is_anonymous: 0,
      resolution: null
    },
    {
      id: 4,
      title: "Parking Space Shortage",
      description: "There aren't enough parking spaces for all employees. Some of us have to park far away and walk to the office.",
      created_at: "2023-06-10T08:30:00",
      is_anonymous: 0,
      resolution: "We are negotiating with the building management to allocate more parking spaces."
    },
    {
      id: 5,
      title: "Outdated Computer Equipment",
      description: "The computers in the design department are outdated and slow, affecting productivity and causing frustration.",
      created_at: "2023-06-15T11:20:00",
      is_anonymous: 1,
      resolution: null
    },
    {
      id: 6,
      title: "Noisy Work Environment",
      description: "The open office layout is causing too much noise and distraction. It's hard to concentrate on tasks that require focus.",
      created_at: "2023-06-22T13:40:00",
      is_anonymous: 0,
      resolution: "We are looking into noise-cancelling solutions and possibly reorganizing the office layout."
    },
    {
      id: 7,
      title: "Insufficient Training Resources",
      description: "New employees are not receiving adequate training materials and resources, making onboarding difficult.",
      created_at: "2023-07-01T10:00:00",
      is_anonymous: 1,
      resolution: null
    },
    {
      id: 8,
      title: "Restroom Cleanliness",
      description: "The restrooms on the second floor are not being cleaned regularly enough. This is a hygiene concern.",
      created_at: "2023-07-10T15:30:00",
      is_anonymous: 0,
      resolution: "Cleaning schedule has been increased to twice daily."
    },
    {
      id: 9,
      title: "Inadequate Meeting Rooms",
      description: "We don't have enough meeting rooms to accommodate all teams. It's often difficult to find a space for impromptu meetings.",
      created_at: "2023-07-15T09:45:00",
      is_anonymous: 0,
      resolution: null
    },
    {
      id: 10,
      title: "Slow Response from IT Support",
      description: "IT support takes too long to respond to tickets, causing delays in work when technical issues arise.",
      created_at: "2023-07-20T14:15:00",
      is_anonymous: 1,
      resolution: "We are hiring additional IT support staff to improve response times."
    }
  ],
  total: 10,
  current_page: 1,
  per_page: 10,
  last_page: 1
};

// Helper functions to simulate API operations
export const complaintsAPI = {
  // Get all complaints with pagination and search
  get: ({ page = 1, keyword = '' }) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        let filteredData = [...complaintsData.data];
        
        // Apply search filter if keyword exists
        if (keyword) {
          filteredData = filteredData.filter(item => 
            item.title.toLowerCase().includes(keyword.toLowerCase()) ||
            item.description.toLowerCase().includes(keyword.toLowerCase())
          );
        }
        
        // Calculate pagination
        const startIndex = (page - 1) * 10;
        const paginatedData = filteredData.slice(startIndex, startIndex + 10);
        
        resolve({
          data: {
            status: "success",
            data: {
              data: paginatedData,
              total: filteredData.length,
              current_page: page,
              per_page: 10,
              last_page: Math.ceil(filteredData.length / 10)
            }
          }
        });
      }, 500); // Simulate network delay
    });
  },
  
  // Get complaint details by ID
  getDetails: ({ id }) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const complaint = complaintsData.data.find(item => item.id === parseInt(id));
        
        if (complaint) {
          resolve({
            data: {
              status: "success",
              data: complaint
            }
          });
        } else {
          reject({
            data: {
              status: "error",
              message: "Complaint not found"
            }
          });
        }
      }, 500);
    });
  },
  
  // Add new complaint
  add: (data) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const newId = Math.max(...complaintsData.data.map(item => item.id)) + 1;
        const newComplaint = {
          id: newId,
          title: data.title,
          description: data.description,
          is_anonymous: data.is_anonymous,
          created_at: new Date().toISOString(),
          resolution: null
        };
        
        complaintsData.data.unshift(newComplaint);
        complaintsData.total += 1;
        
        resolve({
          data: {
            status: "success",
            message: "Complaint added successfully",
            data: newComplaint
          }
        });
      }, 500);
    });
  },
  
  // Update existing complaint
  update: (data) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const index = complaintsData.data.findIndex(item => item.id === parseInt(data.id));
        
        if (index !== -1) {
          const updatedComplaint = {
            ...complaintsData.data[index],
            title: data.title,
            description: data.description,
            is_anonymous: data.is_anonymous
          };
          
          complaintsData.data[index] = updatedComplaint;
          
          resolve({
            data: {
              status: "success",
              message: "Complaint updated successfully",
              data: updatedComplaint
            }
          });
        } else {
          reject({
            data: {
              status: "error",
              message: "Complaint not found"
            }
          });
        }
      }, 500);
    });
  },
  
  // Delete complaint
  delete: ({ id }) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const index = complaintsData.data.findIndex(item => item.id === parseInt(id));
        
        if (index !== -1) {
          complaintsData.data.splice(index, 1);
          complaintsData.total -= 1;
          
          resolve({
            data: {
              status: "success",
              message: "Complaint deleted successfully"
            }
          });
        } else {
          resolve({
            data: {
              status: "error",
              message: "Complaint not found"
            }
          });
        }
      }, 500);
    });
  }
};