import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  Box
} from "@mui/material";
import PageHeader from "../../common/page-header";
import { Fragment, useCallback, useState } from "react";
import { Employee } from "../../../../api/Endpoints/Employee";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../../../Custom.css";
import EditIcon from "@mui/icons-material/Edit";
import ReactSelector from "react-select";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Waveform } from "@uiball/loaders";
import { toast } from "react-toastify";

import SearchField from "../../../../components/searchEntry/Index";
import moment from "moment";
import { CheckCircle, CheckRounded, HighlightOff, Security } from "@mui/icons-material";
import AccessModal from "../employees/AccessModal";
import { useDetailContext } from "../../../../contexts/UserDetail";
import { debounce } from "../../../../components/Debouncer";
const scheme = yup.object().shape({});

const EmployeePermissions = () => {

  const { userDetails } = useDetailContext();

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 170,
      renderCell: (params) => (
        <>
          <Typography
            sx={{
              color:
                params.row.employee_status === "Employee" ? "red" : "black",
              fontWeight: 500,
              cursor: "pointer"
            }}
          >
            {params.value}
          </Typography>
        </>
      )
    },

    {
      field: "has_work_portal_access", headerName: "Works Portal", width: 150, renderCell: (params) => (
        <>
          <Typography>
            {
              params?.value ?
                <CheckCircle sx={{ color: "green" }} />
                :
                <HighlightOff sx={{ color: "red" }} />
            }
          </Typography>
        </>
      )
    },
    {
      field: "has_hr_portal_access", headerName: "HR Portal", width: 150, renderCell: (params) => (
        <>
           <Typography>
            {
              params?.value ?
                <CheckCircle sx={{ color: "green" }} />
                :
                <HighlightOff sx={{ color: "red" }} />
            }
          </Typography>
        </>
      )
    },
    {
      field: "has_accounts_portal_access", headerName: "Accounts Portal", width: 150, renderCell: (params) => (
        <>
           <Typography>
            {
              params?.value ?
                <CheckCircle sx={{ color: "green" }} />
                :
                <HighlightOff sx={{ color: "red" }} />
            }
          </Typography>
        </>
      )
    },
    {
      field: "has_client_portal_access", headerName: "Client Portal", width: 150, renderCell: (params) => (
        <>
          <Typography>
            {
              params?.value ?
                <CheckCircle sx={{ color: "green" }} />
                :
                <HighlightOff sx={{ color: "red" }} />
            }
          </Typography>
        </>
      )
    },
    {
      field: "has_admin_portal_access", headerName: "Admin Dashboard", width: 150, renderCell: (params) => (
        <>
          <Typography>
            {
              params?.value ?
                <CheckCircle sx={{ color: "green" }} />
                :
                <HighlightOff sx={{ color: "red" }} />
            }
          </Typography>
        </>
      )
    },
    {
      field: "has_showcase_portal_access", headerName: "ShowCase", width: 150, renderCell: (params) => (
        <>
           <Typography>
            {
              params?.value ?
                <CheckCircle sx={{ color: "green" }} />
                :
                <HighlightOff sx={{ color: "red" }} />
            }
          </Typography>
        </>
      )
    },
    ...(userDetails?.has_super_admin_access ? [{
      field: "access",
      headerName: "Permissions",
      width: 100,
      renderCell: (params) => (
        <Button
          size="small"
          onClick={() => openAccessModal(params.row.employee_id, params.row?.id)}
        >
          <Security style={{ color: "gray", height: 18 }} />
        </Button>
      )
    }] : []),
  ];

  const { watch, setValue, control } = useForm({});
  const [refresh, setRefresh] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState();
  const [TableUpdate, setTableUpdate] = useState(false);
  const [serachEntry, setSearchEntry] = useState("");
  const [isAccessModalOpen, setIsAccessModalOpen] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  const ForTableUpdate = () => {
    setTableUpdate(true);
    fetchTable();
  };

  const [AccessUserId, setAccessUserId] = useState()
  const openAccessModal = (id, userId) => {
    setSelectedEmployeeId(id);
    setAccessUserId(userId)
    setIsAccessModalOpen(true);
  };

  const closeAccessModal = () => {
    setSelectedEmployeeId(null);
    setIsAccessModalOpen(false);
  };


  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchKeyword(value);
      setTableUpdate(true);
      setPageNumber(0);
    }, 300),
    []
  );

  const [searchValue, setSearchValue] = useState("");
  const [searchLoading, setsearchLoading] = useState(false)
  const handleSearchEntry = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    setsearchLoading(true)
    debouncedSearch(value);
  };

  const clearSerachInput = () => {
    setTableUpdate(true)
    setsearchLoading(true)
    setPageNumber(0);
    setSearchValue("");
    setSearchKeyword();
  };

  useEffect(() => {
    fetchTable();
  }, [
    pageNumber,
    searchKeyword,
  ]);

  //fetch Data with Retry Count

  const fetchTable = (retryCount = 0) => {
    const maxRetries = 3;
    if (!TableUpdate) {
      setLoading(true);
    }
    let pageToFetch = Number(pageNumber) + 1;
    if (watch("employee_role") || searchKeyword) {
      pageToFetch = -3;
    }
    Employee.get({
      keyword: searchKeyword,
      page: pageToFetch,
      limit: 50,
      employee_role: watch("employee_role")?.id,
      employee_level: watch("employee_level"),
      employee_type: watch("employee_type"),
      department: watch("department")
    })
      .then((response) => {
        setList(response.data.data);
        setLoading(false);
        setsearchLoading(false)
      })
      .catch((error) => {
        if (retryCount < maxRetries) {
          console.error(error);
          fetchTable(retryCount + 1);
        } else {
          setLoading(false);
          toast.error(
            "An error occurred. Please try again later or contact the administrator.",
            { autoClose: 6000 }
          );
        }
      })
      .finally(() => {
        if (TableUpdate) {
          setTableUpdate(false);
        }
      });
  };



  return (
    <Fragment>

      <Fragment>
        <Card
          sx={{
            p: 1,
            display: "flex",
            justifyContent: "space-between",
            mb: 1,
            mt: 1,
            ml: 2,
            boxShadow: 0,
            backgroundColor: "transparent"
          }}
        >
          <PageHeader title={"Employees Permissions"} total={list.total} />

          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={searchLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <AccessModal
            isOpen={isAccessModalOpen}
            setIsOpen={setIsAccessModalOpen}
            employeeId={selectedEmployeeId}
            setEmployeeId={setSelectedEmployeeId}
            onUpdate={fetchTable}
            userId={AccessUserId}
            setUserId={setAccessUserId}
          />
        </Card>
        {/* if table update is false and loading is true show the loaidng screen */}
        {!TableUpdate && loading ? (
          <Fragment>
            <Box id="ui-ball-loader">
              <Waveform
                size={50}
                lineWeight={3.5}
                speed={1}
                color="rgb(63,197,149"
              />
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            <Card sx={{ m: 2 }} variant="outlined">
              <Grid
                display={"flex"}
                justifyContent={"space-between"}
                pt={1}
                xs={12}
                sm={12}
              >
                <SearchField
                  clearSerachInput={clearSerachInput}
                  serachEntry={searchValue}
                  handleSearchEntry={handleSearchEntry}
                />

              </Grid>
              <Grid>

              </Grid>
              {list?.data?.length > 0 ? (
                <Fragment>
                  {typeof list.data === "object" && (
                    <DataGrid
                      paginationMode="server"
                      rows={list?.data}
                      columns={columns}
                      page={pageNumber}
                      pageSize={50}
                      rowsPerPageOptions={[50]}
                      rowCount={list.total}
                      onPageChange={handlePageChange}
                      autoHeight
                      density={"comfortable"}
                      getRowHeight={() => 35}
                      sx={{ ml: 1 }}
                    />
                  )}
                </Fragment>
              ) : (
                <Box id="data-not-found-msg">
                  <Typography alignSelf={"center"}>
                    Employee not found.
                  </Typography>
                </Box>
              )}
            </Card>
          </Fragment>
        )}
      </Fragment>

    </Fragment>
  );
};

export default EmployeePermissions;
