import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  Box
} from "@mui/material";
import PageHeader from "../../common/page-header";
import { Fragment, useCallback, useState } from "react";
import { Employee } from "../../../../api/Endpoints/Employee";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import BasicTabs from "./TabsMenu";
import "../../../../Custom.css";
import EditIcon from "@mui/icons-material/Edit";
import Create from "./CreateEmployeeTabs/create";
import ReactSelector from "react-select";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Waveform } from "@uiball/loaders";
import { toast } from "react-toastify";
import {
  fetchEmployeeRoles,
  fetchDepartments
} from "../../../CommonFunction/Index";
import SelectX from "../../../form/SelectX";
import SearchField from "../../../../components/searchEntry/Index";
import AccessModal from "./AccessModal";
import moment from "moment";
import { PermIdentity } from "@material-ui/icons";
import { Security } from "@mui/icons-material";
import { debounce } from "../../../../components/Debouncer";

const scheme = yup.object().shape({});

const Employees = () => {
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 170,
      renderCell: (params) => (
        <>
          <Typography
            sx={{
              color:
                params.row.employee_status === "Employee" ? "red" : "black",
              fontWeight: 500,
              cursor: "pointer"
            }}
            onClick={() => {
              ViewTabs(params.row.employee_id, params.row.id, params.row.name);
            }}
          >
            {params.value}
          </Typography>
        </>
      )
    },


    { field: "role", headerName: "Role", width: 150 },
    { field: "manager_name", headerName: "Manager", width: 150 },
    { field: "joining_date", headerName: "Joining Date", width: 120 },

    {
      field: "Last_Sign_In_Date",
      headerName: "Last Sign In Date",
      width: 140,
      renderCell: (params) => (
        <>
          {" "}
          {params.row.last_login
            ? moment(params.row.last_login).format("DD:MM:YYYY")
            : "-"}
        </>
      )
    },

    {
      field: "edit",
      headerName: "Edit",

      width: 100,
      renderCell: (params) => (
        <>
          <Button
            size="small"
            onClick={() => handleEdit(params.row.employee_id)}
          >
            <EditIcon style={{ color: "gray", height: 18 }} />
          </Button>
        </>
      )
    },

    {
      field: "access",
      headerName: "Permissions",
      width: 100,
      renderCell: (params) => (
        <Button
          size="small"
          onClick={() => openAccessModal(params.row?.employee_id,params.row?.id)}
        >
          <Security style={{ color: "gray", height: 18 }} />
        </Button>
      )
    }
  ];

  const { watch, setValue, control } = useForm({});
  const [refresh, setRefresh] = useState(false);
  const [editId, setEditId] = useState();
  const [CreateEmployeeTab, setCreateEmployeeTab] = useState(false);
  const [Tabs, setTabs] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ID, setID] = useState(0);
  const [searchKeyword, setSearchKeyword] = useState();
  const [TableUpdate, setTableUpdate] = useState(false);
  const [isAccessModalOpen, setIsAccessModalOpen] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [userId, setUserId] = useState(0)
  const ForTableUpdate = () => {
    setTableUpdate(true);
    fetchTable();
  };

  const handleCreate = () => {
    setEditId(0);
    setRefresh(Math.random);
  };
  const handleEdit = (id) => {
    setEditId(parseInt(id));
  };

  const [selectedUserName, setselectedUserName] = useState()
  const ViewTabs = (id, userId, userName) => {
    setselectedUserName(userName)
    setID(id);
    setUserId(userId)
    setTabs(true);
    window.scrollTo({ top: 0});
  };

  const [AccessUserId, setAccessUserId] = useState()
  const openAccessModal = (id,userId) => {
    setSelectedEmployeeId(id);
    setAccessUserId(userId)
    setIsAccessModalOpen(true);
  };

  const closeAccessModal = () => {
    setSelectedEmployeeId(null);
    setIsAccessModalOpen(false);
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchKeyword(value);
      setTableUpdate(true);
      setPageNumber(0);
    }, 300),
    []
  );

  const [searchValue, setSearchValue] = useState("");
  const [searchLoading, setsearchLoading] = useState(false)
  const handleSearchEntry = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    setsearchLoading(true)
    debouncedSearch(value);
  };

  const clearSerachInput = () => {
    setTableUpdate(true)
    setsearchLoading(true)
    setPageNumber(0);
    setSearchValue("");
    setSearchKeyword();
  };

  useEffect(() => {
    fetchTable();
  }, [
    pageNumber,
    searchKeyword,
    watch("employee_role"),
    watch("employee_type"),
    watch("employee_level"),
    watch("department")
  ]);

  //fetch Data with Retry Count

  const fetchTable = (retryCount = 0) => {
    // const maxRetries = 3;
    if (!TableUpdate) {
      setLoading(true);
    }

    Employee.get({
      keyword: searchKeyword,
      page: pageNumber+1,
      limit: 50,
      employee_role: watch("employee_role")?.id,
      employee_level: watch("employee_level"),
      employee_type: watch("employee_type"),
      department: watch("department")?.name
    })
      .then((response) => {
        setList(response.data.data);
        setLoading(false);
        setsearchLoading(false)
      })
      .catch((error) => {
        // if (retryCount < maxRetries) {
        //   console.error(error);
        //   fetchTable(retryCount + 1);
        // } else {
        setLoading(false);
        toast.error(
          "An error occurred. Please try again later or contact the administrator.",
          { autoClose: 6000 }
        );
        // }
      })
      .finally(() => {
        if (TableUpdate) {
          setTableUpdate(false);
        }
      });
  };

  //Dropdown Values

  const EmployeeType = [
    { value: "Consultant", label: "Consultant" },
    { value: "Employee", label: "Employee" },
    { value: "Ex-employee", label: "Ex-employee" }
  ];

  const EmployeeLevel = [
    { value: "Trainee", label: "Trainee" },
    { value: "Intern", label: "Intern" },
    { value: "Executive", label: "Executive" },
    { value: "Manager", label: "Manager" },
    { value: "Senior Executive", label: "Senior Executive" },
    { value: "Director", label: "Director" }
  ];

  return (
    <Fragment>
      {Tabs ? (
        <BasicTabs
          userName={selectedUserName}
          setUserName={setselectedUserName}
          setTabs={setTabs}
          ID={ID}
          userId={userId}
          fetchTable={() => ForTableUpdate()}
        />
      ) : (
        <Fragment>
          <Card
            sx={{
              p: 1,
              display: "flex",
              justifyContent: "space-between",
              mb: 1,
              mt: 1,
              ml: 2,
              boxShadow: 0,
              backgroundColor: "transparent"
            }}
          >
            <PageHeader title={"Employees"} total={list.total} />

            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={searchLoading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Create
              key={refresh}
              // onNew={handleCreate}

              onUpdate={ForTableUpdate}
              editId={editId}
              onNew={handleCreate}
              setEditId={setEditId}
              setCreateEmployeeTabs={setCreateEmployeeTab}
              CreateEmployeeTabs={CreateEmployeeTab}
            />
            <AccessModal
              isOpen={isAccessModalOpen}
              userId={AccessUserId}
              setIsOpen={setIsAccessModalOpen}
              employeeId={selectedEmployeeId}
              setEmployeeId={setSelectedEmployeeId}
              setUserId={setAccessUserId}
              onUpdate={fetchTable}
            />
          </Card>
          {/* if table update is false and loading is true show the loaidng screen */}
          {!TableUpdate && loading ? (
            <Fragment>
              <Box id="ui-ball-loader">
                <Waveform
                  size={50}
                  lineWeight={3.5}
                  speed={1}
                  color="rgb(63,197,149"
                />
              </Box>
            </Fragment>
          ) : (
            <Fragment>
              <Card sx={{ m: 2 }} variant="outlined">
                <Grid
                  display={"flex"}
                  justifyContent={"space-between"}
                  pt={1}
                  xs={12}
                  sm={12}
                >
                  <SearchField
                    clearSerachInput={clearSerachInput}
                    serachEntry={searchValue}
                    handleSearchEntry={handleSearchEntry}
                  />
                  <Grid display={"flex"}>
                    <Grid mr={1} sx={{ width: 200 }}>
                      <ReactSelector
                        placeholder="Employee Type"
                        options={EmployeeType}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 })
                        }}
                        name="employee_type"
                        isClearable
                        value={EmployeeType.find(
                          (options) => options.value === watch("employee_type")
                        )}
                        onChange={(selectedValue) =>
                          setValue("employee_type", selectedValue?.value) ||
                          setPageNumber(0)
                        }
                      />
                    </Grid>
                    <Grid mr={1} sx={{ width: 200 }}>
                      <SelectX
                        placeholder={"Employee Role"}
                        options={fetchEmployeeRoles}
                        control={control}
                        name={"employee_role"}
                      />
                    </Grid>
                    <Grid mr={1} sx={{ width: 200 }}>
                      <SelectX
                        placeholder={"Depatment"}
                        options={fetchDepartments}
                        control={control}
                        name={"department"}
                      />
                    </Grid>
                    <Grid sx={{ width: 200 }}>
                      <ReactSelector
                        placeholder="Employee Level"
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 })
                        }}
                        name="employee_level"
                        isClearable
                        options={EmployeeLevel}
                        value={EmployeeLevel.find(
                          (options) => options.value === watch("employee_level")
                        )}
                        onChange={(selectedValue) =>
                          setValue("employee_level", selectedValue?.value) ||
                          setPageNumber(0)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {list?.data?.length > 0 ? (
                  <Fragment>
                    {typeof list.data === "object" && (
                      <DataGrid
                        paginationMode="server"
                        rows={list?.data}
                        columns={columns}
                        page={pageNumber}
                        pageSize={50}
                        rowsPerPageOptions={[50]}
                        rowCount={list.total}
                        onPageChange={handlePageChange}
                        autoHeight
                        density={"comfortable"}
                        getRowHeight={() => 35}
                        sx={{ ml: 1 }}
                      />
                    )}
                  </Fragment>
                ) : (
                  <Box id="data-not-found-msg">
                    <Typography alignSelf={"center"}>
                      Employee not found.
                    </Typography>
                  </Box>
                )}
              </Card>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Employees;
