import { get, post, put } from '../Config/config';

export const LeaveLedgerApi = {
    get: (data) => get(`hr/leave-ledger`,{ params: data }),
    getDetails: (id) => get(`hr/leave-ledger/${id}`),
    getCategory:(data)=>get(`/hr/leave-ledger-category`,{ params: data }),
    add: (data)=>post(`/hr/leave-ledger?${data}` ),
    update:(id,data) => put(`hr/leave-ledger/${id}`,data)
}

