
import React, { createContext, useState, useContext, useEffect } from 'react';
import { Users } from '../api/Endpoints/Users';


const UserDetailContext = createContext();


export const useDetailContext = () => useContext(UserDetailContext);


export const DetailProvider = ({ children }) => {
    const [userDetails, setUserDetails] = useState(null);
    const fetchUserDetail = async () => {
        try {
            const response = await Users.me()
            setUserDetails(response?.data?.data)
        } catch (error) {

        }

    }

    useEffect(() => {
        fetchUserDetail()
    }, [])


    return (
        <UserDetailContext.Provider value={{ userDetails, }}>
            {children}
        </UserDetailContext.Provider >
    );
};
