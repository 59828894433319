import { Backdrop, Button, TextField, Grid, Card, CircularProgress, IconButton, InputAdornment, Modal,Typography,InputLabel,Select,MenuItem,FormControl } from "@mui/material";
import PageHeader from "../../../../common/page-header";
import Create from "./create";
import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Delete from "../../../../../../components/Popups/Delete";
import View from "./View";

import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from "@mui/icons-material/Search";
import { LeaveApplications } from "../../../../../../api/Endpoints/LeaveApplication";
import { Check } from "@mui/icons-material";
import { Controller,useForm } from "react-hook-form";
import ApproveLeaveModal from "./Modal";
import CurrentDateInput from "../../../../../form/CurrenDateInput"
import ReactSelectorComponent from "../../../../common/ReactSelector";

const approvalStatusOptions = [
    { value: "Not Reviewed", label: "Not Reviewed" },
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
    { value: "Pending", label: "Pending" }
  ];
  
const LeaveApplication = () => {
    const {control,watch} = useForm()
    const columns = [
        {
            field: 'employee_name',
            headerName: 'Employee Name',
            width: 150,
            renderCell: (params) => (<>{params.row.employee?.name}</>),
            headerClassName: 'customHeaderCell',
        },
        {
            field: 'start_date',
            headerName: 'Start Date',
            width: 150,
            renderCell: (params) => (<>{params.row.start_date}</>),
            headerClassName: 'customHeaderCell',
        },
        {
            field: 'end_date',
            headerName: 'End Date',
            width: 150,
            renderCell: (params) => (<>{params.row.end_date}</>),
            headerClassName: 'customHeaderCell',
        },
        {
            field: 'count',
            headerName: 'Leave Count',
            width: 120,
            renderCell: (params) => (<>{params.row.count}</>),
            headerClassName: 'customHeaderCell',
        },
        {
            field: 'manager_approval_status',
            headerName: 'Manager Approval',
            width: 180,
            renderCell: (params) => (<>{params.row.manager_approval_status}</>),
            headerClassName: 'customHeaderCell',
        },
        {
            field: 'manager_name',
            headerName: 'Manager',
            width: 180,
            renderCell: (params) => (<>{params.row.manager?.name}</>),
            headerClassName: 'customHeaderCell',
        },
        {
            field: 'hr_approval_status',
            headerName: 'HR Approval',
            width: 150,
            renderCell: (params) => (<>{params.row.hr_approval_status}</>),
            headerClassName: 'customHeaderCell',
        },
        // {
        //     field: 'edit',
        //     headerName: 'Actions',
        //     width: 120,
        //     align: 'center',
        //     headerAlign: 'center',
        //     renderCell: (params) => (
        //         <Button size="small" onClick={() => handleEdit(params.row.id)}>
        //             <EditIcon style={{ color: 'gray', height: 19 }} />
        //         </Button>
        //     ),
        // },
        {
            field: 'approve',
            headerName: 'Approve',
            width: 120,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => (
                <Button 
                    variant="contained" 
                    size="small" 
                    onClick={() => handleApprove(params.row)}
                >
                    Change Status
                </Button>
            ),
            
        },
    ];

    const [refresh, setRefresh] = useState(0);
    const [editId, setEditId] = useState(null);
    const [showDetails, setDetails] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [list, setList] = useState({ data: [], total: 0 });
    const [loading, setLoading] = useState(false);
    const [ID, setID] = useState(null);
    const [showDeletePopup, setDeletePopup] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [approveId, setApproveId] = useState(null)
    const [formValues, setFormValues] = useState({});

    const handleSetValue = (name, value) => {
      setFormValues((prev) => ({ ...prev, [name]: value }));
    };
    const handleCreate = () => {
        setEditId(0);
        setRefresh((prev) => prev + 1);
    };

    const handleUpdate = () => {
        setPageNumber(0);
        setRefresh((prev) => prev + 1);
    };

    const handleEdit = (id) => {
        setEditId(id);
        setRefresh((prev) => prev + 1);
    };

    const handleApprove = (row) => {
        if (!row) {
            console.error("No row data passed to handleApprove!");
            return;
        }
        setIsOpen(true);
        setApproveId(row?.id); // Use the ID to fetch or display details
        setFormValues(row); // Pass the full row to populate the modal, if needed
        // Debug
    };
    

    useEffect(() => {
        fetchTable();
    }, [searchKeyword, pageNumber, refresh]);

    const handlePageChange = (newPage) => {
        setPageNumber(newPage);
    };

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    };

    const fetchTable = () => {
        setLoading(true);
        LeaveApplications.get({
            page: pageNumber + 1,
            search: searchKeyword,
        })
            .then((response) => {
                setList(response.data);
            })
            .catch(() => setList({ data: [], total: 0 }))
            .finally(() => setLoading(false));
    };

    return (
        <>
            <Card sx={{ p: 1, display: "flex", justifyContent: "space-between", mb: 1, mt: 1,ml:2, boxShadow: 0 }} className="page_header">
                <PageHeader title={"Leave Applications"} />
               
                <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} />
            </Card>

  

            {showDetails && <View ID={ID} setID={setID} setDetails={setDetails} />}

            <ApproveLeaveModal 
                isOpen={isOpen} 
                setIsOpen={setIsOpen} 
                approveId={approveId} 
                setApproveId={setApproveId}
                onUpdate={handleUpdate}
                formValues={formValues}
            />


            <Card sx={{ m: 2 }} variant="">
                <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                 <Grid container sx={{display:"flex",justifyContent:"space-between",pt:1}}>
                <Grid item sx={{pt:1}}>
                    <TextField
                        variant="outlined"
                        placeholder="Search"
                        color="primary"
                        onChange={handleSearchEntry}
                        InputProps={{
                            style: { borderRadius: 20, marginLeft: 10, backgroundColor: "rgb(248,250,251)" },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton disabled>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                 
                   <Grid sx={{display:"flex",justifyContent:"space-around",p:1,gap:2}}>
                    <Grid>
                        <InputLabel sx={{color:"black"}}>Status</InputLabel>
                    <ReactSelectorComponent
                    options={approvalStatusOptions}
                    setValue={handleSetValue}
                    placeholder="Select Approval Status"
                    name="approvalStatus"
                    />
                    </Grid>
                   <Grid >
              <CurrentDateInput
                control={control}
                name="from"
                value={watch("from")}
                label="From"
              />
            </Grid>
            <Grid>
              <CurrentDateInput
                control={control}
                name="to"
                value={watch("to")}
                label="To"
              />
            </Grid>
                   </Grid>
                    </Grid>

                    <DataGrid
                    rows={list.data || []}
                    columns={columns}
                    page={pageNumber}
                    pageSize={10}
                    rowCount={list?.total}
                    rowsPerPageOptions={[10]}
                    paginationMode="server"
                    onPageChange={handlePageChange}
                    autoHeight
                    density={"comfortable"}
                    sx={{ml:1}}
                />
            </Card>
        </>
    );
};

export default LeaveApplication;
