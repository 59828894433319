


import { Backdrop, Button, TextField, Grid, Card, CircularProgress, IconButton, InputAdornment, Modal,Typography,InputLabel,Select,MenuItem,FormControl } from "@mui/material";
import PageHeader from "../../../../common/page-header";
import Create from "./create";
import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";


import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from "@mui/icons-material/Search";
import { LeaveApplications } from "../../../../../../api/Endpoints/LeaveApplication";
import { Check } from "@mui/icons-material";
import { Controller,useForm } from "react-hook-form";
import { LeaveLedgerApi } from "../../../../../../api/Endpoints/LeaveLedger";
import CurrentDateInput from '../../../../../form/CurrenDateInput'

const approvalStatusOptions = [
    { value: "Not Reviewed", label: "Not Reviewed" },
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
    { value: "Pending", label: "Pending" }
  ];
  
const LeaveEligibility = () => {
    const {control,watch} = useForm()
    const columns = [
        {
            field: 'category_name',
            headerName: 'Leave Category',
            width: 150,
            renderCell: (params) => (<>{params.row.category?.category_name}</>),
            headerClassName: 'customHeaderCell',
        },
        {
            field: 'eligibility_date',
            headerName: 'Eligible Date',
            width: 150,
            renderCell: (params) => (<>{params.row.eligibility_date}</>),
            headerClassName: 'customHeaderCell',
        },
        {
            field: 'count',
            headerName: 'Leave Count',
            width: 120,
            renderCell: (params) => (<>{params.row.count}</>),
            headerClassName: 'customHeaderCell',
        },
        {
            field: "edit",
            headerName: "Edit",
            width: 110,
            align: "left",
            headerAlign: "left",
            renderCell: (params) => (
              <Button size="small" onClick={() => handleEdit(params.row.id)}>
                <EditIcon style={{ color: "gray", height: 19 }} />
              </Button>
            )
          },
      
    ];

    const [refresh, setRefresh] = useState(0);
    const [editId, setEditId] = useState(null);
    const [showDetails, setDetails] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [list, setList] = useState({ data: [], total: 0 });
    const [loading, setLoading] = useState(false);
    const [ID, setID] = useState(null);
    const [showDeletePopup, setDeletePopup] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [approveId, setApproveId] = useState(null)
    const handleCreate = () => {
        setEditId(0);
        setRefresh((prev) => prev + 1);
    };

    const handleUpdate = () => {
        setPageNumber(0);
        setRefresh((prev) => prev + 1);
    };

    const handleEdit = (id) => {
        setEditId(id);
        setRefresh((prev) => prev + 1);
    };



    useEffect(() => {
        fetchTable();
    }, [searchKeyword, pageNumber, refresh]);

    const handlePageChange = (newPage) => {
        setPageNumber(newPage);
    };

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
    };

    const fetchTable = () => {
        setLoading(true);
        LeaveLedgerApi.get({
            page: pageNumber + 1,
            search: searchKeyword,
            limit:10
        })
            .then((response) => {
                
                
              
              
                setList(response.data);
            })
            .catch(() => setList({ data: [], total: 0 }))
            .finally(() => setLoading(false));
    };

    return (
        <>
            <Card sx={{ p: 1,ml:2, display: "flex", justifyContent: "space-between", mb: 1, mt: 1, boxShadow: 0 }} className="page_header">
                <PageHeader title={"Leave Ledger"} />
             
                <Create key={refresh} onNew={handleCreate} onUpdate={handleUpdate} editId={editId} setEditId={setEditId} />
            </Card>

        
            {/* {showDetails && <View ID={ID} setID={setID} setDetails={setDetails} />} */}


            <Card sx={{ m: 2 }} variant="outlined">
                <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid container justifyContent={"space-between"}>
                <Grid mt={1}>
                    <TextField
                        variant="outlined"
                        placeholder="Search"
                        color="primary"
                        onChange={handleSearchEntry}
                        InputProps={{
                            style: { borderRadius: 20, marginLeft: 10, backgroundColor: "rgb(248,250,251)" },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton disabled>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid sx={{display:"flex",justifyContent:"space-around",p:1,gap:2}}>
                   <Grid >
              <CurrentDateInput
                control={control}
                name="from"
                value={watch("from")}
                label="Eligible Date"
              />
            </Grid>
                    <Grid>
                        <InputLabel sx={{color:"black"}}>Count</InputLabel>
                        <TextField
                        variant="outlined"
                        size="small"
                        />
                    </Grid>
                   </Grid>

                </Grid>
       
                <DataGrid
                    rows={list.data || []}
                    columns={columns}
                    page={pageNumber}
                    pageSize={10}
                    rowCount={list.total}
                    rowsPerPageOptions={[10]}
                    paginationMode="server"
                    onPageChange={handlePageChange}
                    autoHeight
                    density={"comfortable"}
                    sx={{ml:2}}
                />
            </Card>
        </>
    );
};

export default LeaveEligibility;














// import React, { useEffect, useState, Fragment } from "react";
// import moment from "moment";
// import {
//   Card,
//   Grid,
//   Box,
//   Typography,
//   TextField,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   Button,
//   Table,
//   TableBody,
//   TableCell,
//   TableRow,
//   TableHead,
// } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import { fetchEmployees } from "../../../../../CommonFunction/Index";
// import PageHeader from "../../../../common/page-header";
// import SelectX from "../../../../../form/SelectX";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { LeaveEligibilityApi } from "../../../../../../api/Endpoints/LeaveEligibility";
// import { useForm } from "react-hook-form";
// import { WaveformSpinner } from "../../../../../../components/Loader";

// import Create from "./create";

// const LeaveEligibility = () => {
//   const { watch, setValue, control } = useForm();
//   const [list, setList] = useState([]);
//   const [refresh, setRefresh] = useState(false);
//   const [editId, setEditId] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [isDialogOpen, setIsDialogOpen] = useState(false);
//   const [selectedEmployeeData, setSelectedEmployeeData] = useState(null);
//   const [ledgerDetails, setLedgerDetails] = useState([]);
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [isEditMode, setIsEditMode] = useState(false);
//   const [editData, setEditData] = useState('');

//   const handleCreate = () => {
//     setEditId(null);
//     setIsEditMode(false);
//     setRefresh(prev => !prev);  // Toggle to force a re-render
//   };

//   const handleEdit = (id) => {
//     setEditId(id);
//     setIsEditMode(true);
//     setRefresh(prev => !prev);  // Toggle to force a re-render
//   };

//   const fetchTable = async (selectedEmployee) => {
//     try {
//       setLoading(true);
//       const response = await LeaveEligibilityApi.get({
//         users_id: selectedEmployee,
//       });
//       setList(response.data.data.data);
//     } catch (error) {
//       console.error("Error fetching table data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const UpdateRequest = async () => {
//     setLoading(true);
//     const selectedEmployee = watch("employee")?.id;
//     try {
//       await LeaveEligibilityApi.auto_update({ users_id: selectedEmployee });
//       fetchTable(selectedEmployee);
//     } catch (error) {
//       console.error("Error updating data:", error);
//     }
//   };


// const handleEmployeeClick = async (employee) => {
//     if (employee) {
//         setSelectedEmployeeData(employee);
//         const selectedEmployeeId = watch("employee")?.id;
//         try {
//             const response = await LeaveEligibilityApi.get({
//                 user_id: selectedEmployeeId,
//             });
//             setLedgerDetails(response.data.data.data);
//             setIsDialogOpen(true); 
//         } catch (error) {
//             console.error("Error fetching ledger details:", error);
//         }
//     }
// };

//   const handleCloseDialog = () => {
//     setIsDialogOpen(false);
//     setSelectedEmployeeData(null);
//     setLedgerDetails([]);
//   };

//   useEffect(() => {
//     if (watch("employee")) {
//       UpdateRequest();
//     } else {
//       setList([]);
//     }
//   }, [watch("employee")]);

//   const fetchEditData = async () => {
//     if (editId) {
//       const response = await LeaveEligibilityApi.get({ id: editId });
//       setEditData(response.data.data);
//       setValue('role', response.data.data.name);
//     }
//   };

//   useEffect(() => {
//     if (isEditMode && editId) {
//       fetchEditData();
//     }
//   }, [isEditMode, editId]);

//   const columns = [
//     {
//       field: "leave_awarded_on",
//       headerName: "Date",
//       flex: 1,
//       renderCell: (params) => moment(params.value).format("DD-MMM-YYYY"),
//     },
//     {
//       field: "user",
//       headerName: "Employee",
//       flex: 1,
//       valueGetter: (params) => params.row.user?.name || "",
//       renderCell: (params) => (
//         <Typography
//           sx={{ color: "blue", cursor: "pointer" }}
//           onClick={() => handleEmployeeClick(params.row.user)}
//         >
//           {params.row.user?.name || ""}
//         </Typography>
//       ),
//     },
//     { field: "title", headerName: "Title", flex: 1 },
//     {
//       field: "period",
//       headerName: "Period",
//       flex: 1,
//       valueGetter: (params) =>
//         moment(params.row.leave_awarded_on).format("MMM-YYYY"),
//     },
//     { field: "leave_type", headerName: "Category", flex: 1 },
//     {
//       field: "leave_count",
//       headerName: "Count",
//       flex: 1,
//       renderCell: (params) => params.value?.toFixed(4),
//     },
//     {
//       field: "actions",
//       headerName: "Actions",
//       flex: 1,
//       renderCell: (params) => (
//         <Button
//           variant="outlined"
//           color="primary"
//           size="small"
//           onClick={() => handleEdit(params.row.id)}
//         >
//           Edit
//         </Button>
//       ),
//     },
//   ];

//   return (
//     <>
//       <Card sx={{ p: 1, display: "flex", justifyContent: "space-between", mb: 1, mt: 1 }}>
//         <PageHeader title={"Leave Ledger"} />
//       </Card>

//       <Card sx={{ m: 2 }} variant="outlined">
//         <Grid container mt={2} sx={{ padding: "10px" }}>
//           <Grid item xs={2} mr={2}>
//             <SelectX
//               options={fetchEmployees}
//               placeholder="Employee"
//               control={control}
//               name="employee"
//             />
//           </Grid>
//           <Grid item xs={2} mr={2}>
//             <DatePicker
//               label="Start Date"
//               value={startDate}
//               onChange={(newValue) => setStartDate(newValue)}
//               renderInput={(params) => <TextField {...params} />}
//             />
//           </Grid>
//           <Grid item xs={2} mr={2}>
//             <DatePicker
//               label="End Date"
//               value={endDate}
//               onChange={(newValue) => setEndDate(newValue)}
//               renderInput={(params) => <TextField {...params} />}
//             />
//           </Grid>
//           <Grid item xs={2} mr={2}>
//             <Create
//               key={refresh}
//               onNew={handleCreate}
//               editId={editId}
//               setEditId={setEditId}
//               isEditMode={isEditMode}
//               setIsEditMode={setIsEditMode}
//             />
//           </Grid>
//         </Grid>

//         {loading ? (
//           <Box id="ui-ball-loader">
//             <WaveformSpinner />
//           </Box>
//         ) : (
//           <Fragment>
//             {list.length > 0 ? (
//               <Box sx={{ height: 400, width: "100%", mt: 2 }}>
//                 <DataGrid
//                   rows={list}
//                   columns={columns}
//                   pageSize={5}
//                   rowsPerPageOptions={[5, 10, 20]}
//                   getRowId={(row) => row.id}
//                   disableSelectionOnClick
//                 />
//               </Box>
//             ) : (
//               <Box id="data-not-found-msg">
//                 <Typography alignSelf={"center"}>Please select an employee</Typography>
//               </Box>
//             )}
//           </Fragment>
//         )}
//       </Card>

//       {/* Dialog for Employee Details and Ledger */}
//       <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
//         <DialogTitle>LEDGER DETAILS</DialogTitle>
//         <DialogContent>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell>Date</TableCell>
//                 <TableCell>Employee</TableCell>
//                 <TableCell>Title</TableCell>
//                 <TableCell>Period</TableCell>
//                 <TableCell>Category</TableCell>
//                 <TableCell>Count</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {ledgerDetails.map((row) => (
//                 <TableRow key={row.id}>
//                   <TableCell>{moment(row.leave_awarded_on).format("MMM-YYYY")}</TableCell>
//                   <TableCell>{row.user?.name}</TableCell>
//                   <TableCell>{row.title}</TableCell>
//                   <TableCell>{moment(row.leave_awarded_on).format("MMM-YYYY")}</TableCell>
//                   <TableCell>{row.leave_type}</TableCell>
//                   <TableCell>{row.leave_count}</TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDialog}>Close</Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default LeaveEligibility;