import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Avatar, Button } from "@mui/material";
import Details from './ViewPageTabs/Details';
import FileUploader from './ViewPageTabs/FileUpload';
import SkillandHobbies from './ViewPageTabs/SkillandHobbies';
import EmergencyContact from './ViewPageTabs/EmergencyContact';
import Appraisals from './ViewPageTabs/Appraisals ';
import Ratings from './ViewPageTabs/Ratings';
import Selfies from './ViewPageTabs/Selfies';
import Notes from './ViewPageTabs/Notes';
import Salary from './ViewPageTabs/Salary';


import '../../../../Custom.css'
import Leave from './ViewPageTabs/Leave';
import Activites from './ViewPageTabs/UserActivites';
import Tasks from './ViewPageTabs/Tasks';
import Projects from './ViewPageTabs/Projects';
import WorkPlans from './ViewPageTabs/WorkPlans';
import { Person } from '@mui/icons-material';
import { Close } from '@material-ui/icons';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs({ setTabs, ID, fetchTable, userId, userName, setUserName }) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const handleClose = () => {
        setTabs(false)
        setUserName()
        // fetchTable()
    }

    return (
        <>

            <Box sx={{ width: '100%', }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '13px', marginRight: '13px', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex' }}>
                        <Person sx={{ fontSize: 20 }} />
                        <Typography
                            variant="h6"
                            sx={{
                                color: '#22a777',
                                fontWeight: 'bold',
                                marginLeft: '8px'
                            }}
                        >
                            {userName}
                        </Typography>
                    </div>
                    <div>
                        <Close onClick={handleClose} style={{ cursor: 'pointer' }} />
                    </div>

                </Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="scrollable"
                        scrollButtons="auto"
                        sx={{
                            ml: 2,
                            '& .MuiTab-root.Mui-selected': {
                                color: 'rgb(34,167,119)', // Set the text color to green for the selected tab
                            },
                            '& .MuiTabs-scrollButtons': {
                                color: 'rgb(34,167,119)', // Green color for arrows
                            },
                        }}
                        TabIndicatorProps={{
                            children: <span className='custom-indicator'></span>,
                        }}
                    >
                        <Tab label="Details" {...a11yProps(0)} />
                        <Tab label="Salary" {...a11yProps(1)} />
                        <Tab label="Leave" {...a11yProps(2)} />
                        <Tab label="Documents" {...a11yProps(3)} />
                        <Tab label="Skills and Hobbies" {...a11yProps(4)} />
                        <Tab label="Emergency Contacts" {...a11yProps(5)} />
                        <Tab label="Appraisals" {...a11yProps(6)} />
                        <Tab label="Ratings" {...a11yProps(7)} />
                        <Tab label="Photos" {...a11yProps(8)} />
                        <Tab label="Notes" {...a11yProps(9)} />
                        <Tab label="Activities" {...a11yProps(10)} />
                        <Tab label="Tasks" {...a11yProps(11)} />
                        <Tab label="Projects" {...a11yProps(12)} />
                        <Tab label="Works Plans" {...a11yProps(13)} />

                    </Tabs>

                </Box>
                <TabPanel value={value} index={0}>
                    <Details ID={ID} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Salary />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Leave />
                </TabPanel>
                <TabPanel value={value} index={3}>
                    <FileUploader />
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <SkillandHobbies ID={ID} />
                </TabPanel>
                <TabPanel value={value} index={5}>
                    <EmergencyContact />
                </TabPanel>
                <TabPanel value={value} index={6}>
                    <Appraisals />
                </TabPanel>
                <TabPanel value={value} index={7}>
                    <Ratings ID={ID} />
                </TabPanel>
                <TabPanel value={value} index={8}>
                    <Selfies employeeID={ID} />
                </TabPanel>
                <TabPanel value={value} index={9}>
                    <Notes />
                </TabPanel>
                <TabPanel value={value} index={10}>
                    <Activites employeeID={userId} />
                </TabPanel>
                <TabPanel value={value} index={11}>
                    <Tasks employeeID={userId} />
                </TabPanel>
                <TabPanel value={value} index={12}>
                    <Projects employeeID={userId} />
                </TabPanel>
                <TabPanel value={value} index={13}>
                    <WorkPlans employeeID={userId} />
                </TabPanel>


            </Box>
            <Divider />

            {/* <div style={{ width: '95%', display: 'flex', justifyContent: 'end', marginTop: 5 }}>
                <Button variant="outlined" color='success' onClick={handleClose} style={{ width: 'fit-content', mr: 7 }}>
                    Cancel
                </Button>

            </div> */}
        </>
    );
}
