import { useEffect, useState } from 'react';
import { Task } from '../../../../../api/Endpoints/Tasks';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import { DotSpinner } from '@uiball/loaders';
import { Activities } from '../../../../../api/Endpoints/Activities';
import moment from 'moment';

const UserActivites = ({ employeeID }) => {
  const [activities, setactivities] = useState([]);
  const [loading, setLoading] = useState(false);
  

  const fetchactivities = async () => {
    let data = { users_id: employeeID };
    setLoading(true);
    try {
      const res = await Activities.get(data);
      setactivities(res.data?.data?.data || []);
      setLoading(false);
    } catch (error) {
      
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchactivities();
    
  }, [employeeID]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
        <DotSpinner size={47} speed={0.9} color="rgb(63,197,149)" />
      </Box>
    );
  }

  return (
    <div>
      <TableContainer
        component={Paper}
        sx={{
          mt: 2,
          boxShadow: 3,
          borderRadius: 2,
          overflow: 'hidden',
        }}
      >
        <Table sx={{ minWidth: 750 }}>
          <TableHead
           
          >
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Task Title</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Project Title</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>UserName</TableCell>
              <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activities?.map((activity) => (
              <TableRow
                key={activity.id}
                sx={{
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                  },
                  transition: 'background-color 0.3s ease',
                }}
              >
                <TableCell sx={{ padding: '16px' }}>{activity.title}</TableCell>
                <TableCell sx={{ padding: '16px' }}>{activity.project?.name}</TableCell>
                <TableCell sx={{ padding: '16px' }}>{activity.user?.name}</TableCell>
                <TableCell sx={{ padding: '16px' }}>{moment(activity.created_at).format("YYYY-MM-DD")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UserActivites