import React, { useEffect, useState } from 'react';
import { TextField, Button, Modal, Box } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Selfi } from '../../api/Endpoints/Selfie';
import { toast } from 'react-hot-toast';
import { useStateContext } from '../../contexts/SignIn/UsersID/usersID';
import { Employee } from '../../api/Endpoints/Employee';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    maxHeight: '80vh',
    borderRadius: '8px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
function AddSelfiePopup({ showImageUploadPopup, setShowImagePopup, callFunc, setID, ID, photoType }) {
    const [title, setTitle] = useState('');
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [open, setOpen] = useState(false)
    const { userId } = useStateContext();
    const [err, setErr] = useState({
        title: '',
        phot: '',

    })


    useEffect(() => {
        if (ID > 0) {
            fetchDetails()
            setOpen(true)
        } else if (ID === undefined) {
            setOpen(true)
        }
    }, [])

    
    const fetchDetails = async () => {
        const response = await Selfi.getDetails({ id: ID })
        if (response.data.status === "success") {
            const data = response.data.data
            setTitle(data.title)
            setSelectedPhoto(`https://dashboard.omnisellcrm.com/${data.photo}`);

        }
    }

    const handlePhotoSelect = (event) => {
        const file = event.target.files[0];

        setSelectedPhoto(file)
    };

    const handleClose = () => {
        setShowImagePopup(false);
        setID(0)
        setErr('')
        setOpen(false)
        setSelectedPhoto(null)
    };





    const handleSubmit = async (event) => {

        event.preventDefault();

        if (!title && !selectedPhoto) {
            setErr(prevErr => ({
                ...prevErr,
                title: 'field is requried',
                photo: 'photo is requried',


            }));
            return
        }

        if (!title) {
            setErr(prevErr => ({
                ...prevErr,
                title: 'field is requried',
            }));
            return
        }

        if (selectedPhoto == null) {
            setErr(prevErr => ({
                ...prevErr,
                photo: 'photo is requried',
            }));
            return
        }
        const formData = new FormData()

        formData.append('id', ID)


        let action;

        if (photoType === 'family') {
            formData.append('family_photo_file', selectedPhoto);
            action = await Selfi.addSelfie(formData);
            
        } else {
            formData.append('selfi_file', selectedPhoto);
            action = await Selfi.addFamilyPhoto(formData);
            
        }
        
        


        if (action.data.message === "New photo has been successfully saved.") {
            toast.success('New photo has been successfully saved')
        }



        if (action.data.message === "Photo has been successfully updated.") {
            toast.success('Photo has been successfully updated.')
        }

        callFunc()
        handleClose();
    };
    return (

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

            }}
        >
            <>

                <form onSubmit={handleSubmit}>
                    <Box sx={style}>
                        <TextField
                            fullWidth
                            label="Title"
                            variant="outlined"
                            value={title}
                            onChange={(event) => setTitle(event.target.value)}
                        />
                        {err.title && <p style={{ color: 'red', margin: 0 }}>{err.title}</p>}
                        <Button variant="contained" color="primary" component="label" sx={{ mt: 4 }}>
                            <PhotoCameraIcon /> Upload Photo
                            <input type="file" accept="image/*" hidden onChange={handlePhotoSelect} />
                        </Button>
                        {err.photo && <p style={{ color: 'red', margin: 3, }}>{err.photo}</p>}
                        {selectedPhoto && (
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%', marginTop: 30 }}>
                                {/* <h3>Selected Photo:</h3> */}
                                <div style={{ border: '1px solid black', width: 'auto', height: '200px', overflow: 'hidden', }}>
                                    {selectedPhoto.name ? (
                                        <img
                                            src={URL.createObjectURL(selectedPhoto)}
                                            alt="Selected"
                                            style={{
                                                width: '100%', height: '100% ', objectFit: 'contain'
                                            }}
                                        />
                                    ) : (


                                        <img
                                            src={selectedPhoto}
                                            alt="Selected"
                                            style={{
                                                width: '100%', height: '100% ', objectFit: 'contain'
                                            }}
                                        />
                                    )}
                                </div>
                                {/* <div style={{ width: '300px', display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                            <Button
                                variant="contained"
                                color="error"
                                startIcon={<DeleteIcon />}
                                onClick={handlePhotoUpload}
                            >
                                Delete
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<CloudUploadIcon />}
                                style={{ marginLeft: '10px' }}
                            >
                                Upload
                            </Button>
                        </div> */}
                            </div>
                        )}
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'end', marginTop: 10 }}>
                            <Button variant="outlined" color="error" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button type='submit' variant="outlined" color="success" sx={{ marginLeft: 1 }}>
                                Submit
                            </Button>
                        </div>
                    </Box>

                </form>
            </>

        </Modal >

    );
}

export default AddSelfiePopup;
