import React, { useState } from 'react';

import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, Slide, TextField, Typography } from "@mui/material";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import TextInput from '../../../form/TextInput'
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { ErrorMessage } from "@hookform/error-message";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from 'react';
import { EmployeeRole } from '../../../../api/Endpoints/EmployeeRoles';
import SelectX from '../../../form/SelectX';
import { Employee } from '../../../../api/Endpoints/Employee';
import DateInput from '../../../form/DateInput'



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
    role: yup.string().required(),
});


const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)


    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            props.setEditId(undefined)
        }, 100)
    };


    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });





    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true });

        let dataToSubmit = {
            id: props?.editId,
            title:data.title,
            employe:data.employe,
            award:data.award,
            description:data.description
        };

        console.log(dataToSubmit)

        let action;
        if (props.editId > 0) {

            
            action = EmployeeRole.update(dataToSubmit);

        } else {
            
            action = EmployeeRole.add(dataToSubmit);

        }

        action.then(response => {
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            if (response.data.errors) {
                let err = response.data.errors
                Object.keys(err).forEach((key) => {
                    toast.error(err[key].name);
                });

                return
            }

            toast.success(response.data.message)

            props.onUpdate();
            setOpen(false)
        }).catch(errors => {
            toast.error("Internal server error");
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            setOpen(false)
        })
    }

    const fetchEmployees = (e)=> {
        return Employee.get({ keyword: e }).then((response) => {
            // 
            if (typeof response.data.data !== "undefined") {
              
              return response.data.data.data
            } else {
              return []
            }
          })
    }
    // const fetchDetails = () => {
    //   setLoading(true);
    //   brands.view({ id: props.editId }).then((response) => {
    //     
    //     let data = response.data.data;
    //     setValue("brand_name", data.brand_name);
    //     setValue("description", data.description);
    //     // setValue("status", data.status ? "true" : "false");
    //     setValue("status", data.status)
    //     setLoading(false);
    //   });
    // };
    useEffect(() => {
        if (parseInt(props.editId) > 0) {
            fetchDetails()
            setOpen(true);
        } else if (Number(props.editId) === 0) {
            setOpen(true);
        }

    }, [props.editId])

  

    const fetchDetails = async () => {
        setLoading(true)
        let details = await EmployeeRole.getDetails({ id: props.editId });
        if (details.data.status === "success") {
            let data = details.data.data;
            
            setValue('role', data.name);
        }
        setLoading(false)

    }


    return (
        <div>
            <Button
                className="create-btn"
                variant="contained"
                onClick={handleClickOpen}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                <AddIcon sx={{ marginRight: "2px" }} />
                Add Award winner
            </Button>

            <Dialog
                open={open}
                PaperProps={{ sx: { width: "40%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Award Winner" : "Create Award Winner"}</DialogTitle>


                {loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }}>
                        <CircularProgress />
                    </Box >
                ) : (
                    <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                        <DialogContent>
  <Grid container spacing={2}>

    {/* Title Field */}
    <Grid item xs={12} sm={6}>
      <TextInput 
        control={control} 
        name="title" 
        label="Title" 
        value={watch('title')} 
        fullWidth 
      />
    </Grid>

    {/* Employee Select */}
    <Grid item xs={12} sm={6}>

      <SelectX
                    // key={refresh * 0.2}
                    label={"Employes"}
                    options={fetchEmployees}
                    control={control}
                    error2={
                      errors?.employe?.message
                        ? errors?.employe?.message
                        : false
                    }
                    name={"employe"}
                    defaultValue={watch("employe")}
                  />
    </Grid>

    {/* Award Select */}
    <Grid item xs={12} sm={6}>
      <TextInput 
        control={control} 
        name="award_select" 
        label="Award" 
        value={watch('award_select')} 
        fullWidth 
      />
    </Grid>

    {/* Awarded Date */}
    <Grid item xs={12} sm={6}>
      <DateInput 
        control={control} 
        name="joining_date" 
        label="Awarded Date" 
        value={watch('joining_date')} 
        fullWidth 
      />
    </Grid>

    {/* Description */}
    <Grid item xs={12}>
      <InputLabel sx={{ color: "black", pb: 1 }}>Description</InputLabel>
      <TextField 
        {...register("description")} 
        variant="outlined" 
        fullWidth 
        multiline 
        rows={3} 
        sx={{ width: "100%" }} 
      />
    </Grid>

    {/* Error Message */}
    <Grid item xs={12}>
      <ErrorMessage
        errors={errors}
        name="multipleErrorInput"
        render={({ messages }) =>
          messages &&
          Object.entries(messages).map(([type, message]) => (
            <Typography key={type} color="error">{message}</Typography>
          ))
        }
      />
    </Grid>

  </Grid>
</DialogContent>


                            <DialogActions>
                                <Button onClick={handleClose} variant='outlined' color='error'>Close</Button>
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" color='success' type="submit">{formButtonStatus.label}</LoadingButton>

                            </DialogActions>
                        </form>
                    </>
                )
                }
            </Dialog >
        </div >

    );
};

export default Create;