import { Button, TextField, Grid, Card, Typography, InputAdornment, IconButton, Box } from "@mui/material";
import PageHeader from "../../common/page-header";
import Create from "./create";
import { useState } from "react";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Delete from "../../../../components/Popups/Delete";
import View from "./View";
import { complaintsAPI } from "./staticData";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Fragment } from "react";
import { Waveform } from "@uiball/loaders";
import { toast } from "react-toastify";
import SearchField from '../../../../components/searchEntry/Index';
import moment from 'moment';

const ComplaintsBox = () => {

    const columns = [
        {
            field: 'created_at', 
            headerName: 'Date', 
            width: 150,
            renderCell: (params) => (
                <Typography>
                    {moment(params.value).format('DD-MM-YYYY')}
                </Typography>
            )
        },
        {
            field: 'title', 
            headerName: 'Title', 
            width: 550, 
            renderCell: (params) => (
                <Typography sx={{ color: 'rgb(0,158,102)', fontWeight: 500, cursor: 'pointer' }}
                    onClick={() => { ViewTabs(params.row.id) }}
                >{params.value}</Typography>
            )
        },
        {
            field: 'is_anonymous',
            headerName: 'Anonymous',
            width: 150,
            renderCell: (params) => (
                <Typography>
                    {params.value ? 'Yes' : 'No'}
                </Typography>
            )
        },
        {
            field: 'edit',
            headerName: 'Edit',
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => (
                <Button size='small' onClick={() => handleEdit(params.row.id)}>
                    <EditIcon style={{ color: 'gray', height: 19 }} />
                </Button>
            ),
        },
        {
            field: 'delete',
            headerName: 'Delete',
            renderCell: (params) => (
                <Button size='small' onClick={() => handleDelete(params.row.id)}>
                    <DeleteIcon style={{ color: 'red', height: 19 }} />
                </Button>
            ),
        },
    ]

    const [refresh, setRefresh] = useState(false)
    const [editId, setEditId] = useState()
    const [showDetails, setDetails] = useState(false)
    const [pageNumber, setPageNumber] = useState(0);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ID, setID] = useState(0)
    const [showDeletePopup, setDeletePopup] = useState(false)
    const [searchKeyword, setSearchKeyword] = useState();
    const [TableUpdate, setTableUpdate] = useState(false)
    const [serachEntry, setSearchEntry] = useState('')


    const handleCreate = () => {
        setEditId(0)
        setRefresh(Math.random)
    }

    const handleEdit = (id) => {
        setRefresh(Math.random);
        setEditId(parseInt(id));
    }

    const ViewTabs = (id) => {
        setID(id)
        setDetails(true)
    }


    const handlePageChange = (page) => {
        setPageNumber(page)
    }

    const handleSearchEntry = (event) => {
        setSearchKeyword(event.target.value);
        setSearchEntry(event.target.value)
        setTableUpdate(true)
    }

    const clearSerachInput = () => {
        setSearchEntry('');
        setSearchKeyword()
        setTableUpdate(true)
    }

    const handleDelete = (id) => {
        setID(id)
        setDeletePopup(true)
    }

    const fetchList = () => {
        setLoading(true)
        complaintsAPI.get({ page: pageNumber + 1, keyword: searchKeyword }).then(response => {
            if (response.data.status === "success") {
                setList(response.data.data)
                setLoading(false)
                setTableUpdate(false)
            }
        }).catch(errors => {
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchList()
    }, [pageNumber, TableUpdate])

    return (
        <Fragment>
            <PageHeader title="Complaints & Reports" />
            <Card sx={{ p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <SearchField
                            value={serachEntry}
                            onChange={handleSearchEntry}
                            clearSearchInput={clearSerachInput}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Create onNew={handleCreate} editId={editId} setEditId={setEditId} onUpdate={() => setTableUpdate(true)} />
                    </Grid>
                </Grid>
                <Box sx={{ height: 400, width: '100%', mt: 2 }}>
                    {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Waveform size={20} color="#3f51b5" />
                        </Box>
                    ) : (
                        <DataGrid
                            rows={list?.data || []}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            disableSelectionOnClick
                            pagination
                            page={pageNumber}
                            rowCount={list?.total || 0}
                            paginationMode="server"
                            onPageChange={handlePageChange}
                        />
                    )}
                </Box>
            </Card>
            {showDetails && <View ID={ID} setID={setID} setDetails={setDetails} />}
            {showDeletePopup && <Delete
                ID={ID}
                setID={setID}
                setDeletePopup={setDeletePopup}
                showDeletePopup={showDeletePopup}
                Callfunc={() => fetchList()}
                url="/complaints/delete?id="
                title="Complaint" />}
        </Fragment>
    );
};

export default ComplaintsBox;