import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
  Typography
} from "@mui/material";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Employee } from "../../../../../../api/Endpoints/Employee";
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import AddIcon from "@mui/icons-material/Add";
import * as yup from "yup";
import SelectX from "../../../../../form/SelectX";
import { LeaveLedgerApi } from "../../../../../../api/Endpoints/LeaveLedger";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Yup Validation Schema
const schema = yup.object().shape({
  employee_user_id: yup.mixed().required("Employee is required"),
  category_id: yup.string().required("Category is required"),
  eligible_date: yup.date().required("Eligible date is required"),
  count: yup.number().required("Count is required").positive("Count must be positive")
});

const Create = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      employee_user_id: "",
      category_id: "",
      eligible_date: "",
      count: "",
    },
    criteriaMode: "all"
  });

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [datas, setDatas] = useState(null)
  const [formButtonStatus, setFormButtonStatus] = useState({
    label: "Submit",
    loading: false,
    disabled: false
  });

  // Fetch employee data
  const fetchEmployees = (e) => {
    return Employee.get({ keyword: e }).then((response) => {
      if (response.data.data) {
        return response.data.data.data;
      } else {
        return [];
      }
    });
  };

  // Fetch categories
  const fetchCategory = async () => {
    try {
      const res = await LeaveLedgerApi.getCategory();
      setCategory(res.data.data);
    } catch (error) {
      
    }
  };

  // Open dialog
  const handleClickOpen = () => {
    props.onNew();
  };

  // Close dialog
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      props.setEditId(undefined);
    }, 100);
  };

  // Fetch details for editing
// Fetch details for editing
const fetchDetails = async () => {
  if (!props.editId) return;

  setLoading(true);
  try {
    const details = await LeaveLedgerApi.getDetails(props.editId);
    setDatas(details.data); // Ensure state is updated first
    
    
  } catch (error) {
    console.error("Error fetching details:", error);
  }
  setLoading(false);
};

// Update form when `datas` is set


  // On form submission
  const onSubmit = async (data) => {
    setFormButtonStatus({ ...formButtonStatus, loading: true });

    const formattedDate = moment(data.eligible_date).format("YYYY-MM-DD");
    const dataToSubmit = {
      category_id: data.category_id || datas?.category_id,
      employee_user_id: data.employee_user_id.id || datas?.employee_user_id,
      count: data.count,
      eligibility_date: formattedDate,
    };

    try {
      let action;
      if (props.editId > 0) {
        action = await LeaveLedgerApi.update(props.editId,dataToSubmit);
      } else {
        action = await LeaveLedgerApi.add(new URLSearchParams(dataToSubmit).toString());
      }

      setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });

      if (action.data.errors) {
        Object.keys(action.data.errors).forEach((key) => {
          toast.error(action.data.errors[key].name);
        });
        return;
      }

      toast.success(action.data.message);
      props.onUpdate();
      setOpen(false);
    } catch (error) {
      toast.error("Internal server error");
      setFormButtonStatus({ label: "Create", loading: false, disabled: false });
      setOpen(false);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  useEffect(() => {
    if (datas) {
      reset({
        employee_user_id: datas.employee?.name || "",
        category_id: datas.category?.category_name || "",
        eligible_date: moment(datas.eligibility_date).format("YYYY-MM-DD"),
        count: datas.count || "",
      });
    }
  }, [datas, reset]);
  
  useEffect(() => {
    if (parseInt(props.editId) > 0) {
      fetchDetails();
      setOpen(true);
    } else if (props.editId === 0) {
      reset();
      setOpen(true);
    }
  }, [props.editId]);
  

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        <AddIcon /> Add Leave
      </Button>

      <Dialog
        open={open}
        PaperProps={{ sx: { width: "40%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: "100%" } }}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>{parseInt(props.editId) > 0 ? "Edit Ledger" : "Create Ledger"}</DialogTitle>

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "30vh" }}>
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SelectX options={fetchEmployees} placeholder="Employee" control={control} name="employee_user_id" value={watch("employee_user_id")} required />
                  {errors.employee_user_id && <Typography color="error">{errors.employee_user_id.message}</Typography>}
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth margin="dense" error={!!errors.category_id}>
                    <InputLabel>Category</InputLabel>
                    <Controller
                      name="category_id"
                      control={control}
                      render={({ field }) => (
                        <Select {...field} label="Category" required>
                          {category.length > 0 ? category.map((cat) => <MenuItem key={cat.id} value={cat.id}>{cat.category_name}</MenuItem>) : <MenuItem disabled>No categories available</MenuItem>}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField label="Eligible Date" type="date" InputLabelProps={{ shrink: true }} {...register("eligible_date")} fullWidth required />
                </Grid>

                <Grid item xs={12}>
                  <TextField label="Count" type="number" {...register("count")} fullWidth />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} variant="outlined" color="error">Close</Button>
              <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled} variant="outlined" color="success" type="submit">{formButtonStatus.label}</LoadingButton>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </div>
  );
};

export default Create;
