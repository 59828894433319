import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

// CSS
const linkStyle = {
    textDecoration: 'none',
    color: 'rgb(0,158,102)',
};

const DocumentView = ({ documentURL, fileName, EditID }) => {
    const [isOpen, setIsOpen] = useState(false);
    
    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    // Document URL Split 
    const getFilenameFromPath = (fullPath) => {
        const filename = fullPath.split('/').pop();
        return filename;
    };

    return (
        <>
            {documentURL || EditID ? (
                <>
                    <Typography
                        sx={{
                            color: 'blue',
                            cursor: 'pointer',
                        }}
                        onClick={openModal}
                    >

                        {getFilenameFromPath(documentURL)}
                    </Typography>
                    <Modal
                        open={isOpen}
                        onClose={closeModal}
                        aria-labelledby="pdf-modal-title"
                        aria-describedby="pdf-modal-description"
                    >
                        <div>
                            <iframe
                                src={`https://dashboard.omnisellcrm.com/${documentURL}`}
                                title={fileName}
                                width="100%"
                                height="500px"
                            ></iframe>
                            <Grid width={'95%'} display={'flex'} justifyContent={'end'}>
                                <Button variant="contained" color="error" onClick={closeModal}>
                                    Close
                                </Button>
                            </Grid>
                        </div>
                    </Modal>
                </>
            ) : (
                <span>Document URL is not available</span>
            )
            }
        </>
    );
};

export default DocumentView;
