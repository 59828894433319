import React, { useState } from 'react';
// import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide, Typography } from "@mui/material";
import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, Slide, Typography } from "@mui/material";
import ReactSelector from 'react-select';

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import SelectX from '../../../form/SelectX'
import TextInput from '../../../form/TextInput'
import { Departments } from '../../../../api/Endpoints/Departments';
import { Employee } from '../../../../api/Endpoints/Employee';
import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";
import { ErrorMessage } from "@hookform/error-message";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from 'react';
import { addDays } from 'date-fns';
import '../../../../Custom.css'
import LeaveFile from '../../../../components/FileUploader/LeaveFile'
import { fetchEmployees } from '../../../CommonFunction/Index';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; //
import DateRangePickerComp from '../../common/DateRangePickerCom';
import moment from 'moment';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const scheme = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),

});


const Create = (props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState()

    const [selectedDateRange, setSelectedDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    const handleDateRangeChange = (newDateRange) => {
        setSelectedDateRange(newDateRange);
    };
    const handleClickOpen = () => {
        props.onNew()
    };

    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            props.setEditId(undefined)
        }, 1000)
    };


    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const fetchEmployeeId = (e) => {
        return Employee.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return response.data.data.data
                // return [{ id: 1, name: "1001" },
                // { id: 1, name: "1002" }
                // ];
            } else {

                return []
            }
        })
    }

    const fetchELeaveType = (e) => {
        return Departments.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return [{ id: 1, name: "HalfDay" },
                { id: 1, name: "FullDay" }
                ];
            } else {

                return response.data.data.data
            }
        })
    }

    const fetchApprovalStatus = (e) => {
        return Employee.get({ keyword: e }).then(response => {
            if (typeof response.data.data.data !== "undefined") {
                return [{ id: 1, name: "Approved" },
                { id: 1, name: "Rejected" }
                ];
            } else {

                return response.data.data.data
            }
        })
    }


    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true });

        let dataToSubmit = {
            id: props?.editId,
            users_id: props?.editId,


            employees_id: data.employees_id,
            date: data.date,
            reason: data.reason,
            leave_type: data.leave_type,
            approved_by: data.approved_by,
            approval_status: data.approval_status

        };


        // let action;
        // if (props.editId > 0) {

        //     action = Departments.update(dataToSubmit);

        // } else {
        //     action = Departments.add(dataToSubmit);

        // }

        // action.then(response => {
        //     setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
        //     setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        //     if (response.data.errors) {
        //         let err = response.data.errors
        //         Object.keys(err).forEach((key) => {
        //             toast.error(err[key].name);
        //         });

        //         return
        //     }

        //     toast.success(response.data.message)

        //     props.onUpdate();
        //     setOpen(false)
        // }).catch(errors => {
        //     toast.error("Internal server error");
        //     setFormButtonStatus({ label: "Create", loading: false, disabled: false });
        //     setOpen(false)
        // })
    }

    let fetchDepartments;

    fetchDepartments = async (e) => {
        const response = await Departments.get({ keyword: e });
        if (typeof response.data.data.data !== "undefined") {
            return response.data.data.data;
        } else {
            return [];
        }



    }


    useEffect(() => {
        if (parseInt(props.editId) > 0) {

            setOpen(true);
        } else if (Number(props.editId) === 0) {
            setOpen(true);
        }

    }, [props.editId])

    const DayType = [
        { value: "Full", label: 'Full' },
        { value: "First Half", label: 'First Half' },
        { value: "Second Half", label: 'Second Half' },
    ];
    const ApprovalStatus = [
        { value: "Applied", label: 'Applied' },
        { value: "Rejected", label: 'Rejected' },
    ]
    const LeaveType = [
        { value: "Sick Leave", label: 'Sick Leave' },
        { value: "Casual Leave", label: 'Casual Leave' },
        { value: "Emergency Leave", label: 'Emergency Leave' },
        { value: "Maternity Leave", label: 'Maternity Leave' },
        { value: "Vacation Leave", label: 'Vacation Leave' },
        { value: "Annual Leave", label: 'Annual Leave' },
        { value: "Other", label: 'Other' },
    ]

    // const fetchDetails = async () => {
    //     setLoading(true)
    //     let DepartmentDetails = await Departments.getDetails({ id: props.editId });
    //     if (DepartmentDetails.data.status === "success") {
    //         let data = DepartmentDetails.data.data;
    //         setValue('name', data.name);
    //         
    //         setValue('employees_id', data.employees_id);
    //         setValue('date', data.date);
    //         setValue('reason', data.reason);
    //         setValue('leave_type', data.leave_type);
    //         setValue('approved_by', data.approved_by)
    //         setValue('approval_status', data.approval_status)
    //         setLoading(false)
    //     }

    // }


    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection',
        },
    ]);

    const handleSelect = (ranges) => {
        setDateRange([ranges.selection]);
    };

    return (
        <div>
            <Button
                className='create-btn'
                variant="contained"
                onClick={handleClickOpen}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <AddIcon sx={{ marginRight: '2px' }} />  {/* Add a margin to the icon if needed */}
                Apply Leave

            </Button>
            <Dialog
                open={open}
                PaperProps={{ sx: { width: "40%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle >{parseInt(props.editId) > 0 ? "Edit Leave" : "Apply Leave"}</DialogTitle>


                {loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }}>
                        <CircularProgress className='ld-circle' />
                    </Box >
                ) : (
                    <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <DialogContent sx={{ p: 2, }}>

                                <Grid item xs={12} sm={12}>

                                    <Grid>
                                        <Grid container spacing={1}>

                                            <Grid item xs={6}>
                                                <SelectX control={control} name="employees_id"
                                                    label="Select Employee"
                                                    value={watch('employees_id')}
                                                    options={fetchEmployeeId}
                                                    defaultValue={watch('employees_id')} />

                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <SelectX
                                                    defaultOptions
                                                    label={"Choose a Department"}
                                                    options={fetchDepartments}
                                                    control={control}
                                                    error={errors.message}
                                                    name={'department'}
                                                    defaultValue={watch('department')}
                                                />
                                            </Grid>

                                        </Grid>

                                        <Grid container spacing={1} mt={2}>

                                            <Grid item xs={6}>
                                                <SelectX control={control}
                                                    name="manager"
                                                    label="Manager"
                                                    options={fetchEmployees}
                                                    defaultValue={watch('manager')}
                                                />
                                            </Grid>

                                            <Grid item xs={6} sm={6}>
                                                <InputLabel sx={{
                                                    color: "black",
                                                }}>Approval Status</InputLabel>
                                                <ReactSelector
                                                    options={ApprovalStatus}
                                                    value={ApprovalStatus.filter(requestoptions => requestoptions.label === watch('approval_status'))}
                                                    name='approval_status'
                                                    defaultValue={watch('approval_status')}
                                                    isClearable="true"
                                                    onChange={(selectedOption) => setValue('approval_status', selectedOption?.value || "")}
                                                    styles={{
                                                        menu: provided => ({ ...provided, zIndex: 9999 })
                                                    }}

                                                />

                                            </Grid>



                                        </Grid>
                                        <Grid container spacing={1} mt={2}>

                                            <Grid item xs={6} sm={6}>
                                                <InputLabel sx={{
                                                    color: "black",
                                                }}>Leave Type</InputLabel>
                                                <ReactSelector
                                                    options={LeaveType}
                                                    value={LeaveType.filter(requestoptions => requestoptions.label === watch('leave_type'))}
                                                    name='leave_type'
                                                    defaultValue={watch('leave_type')}
                                                    isClearable="true"
                                                    onChange={(selectedOption) => setValue('leave_type', selectedOption?.value || "")}
                                                    styles={{
                                                        menu: provided => ({ ...provided, zIndex: 9999 })
                                                    }}

                                                />

                                            </Grid>

                                            <Grid item xs={6} sm={6}>
                                                <InputLabel sx={{
                                                    color: "black",
                                                }}>Day Type</InputLabel>
                                                <ReactSelector
                                                    options={DayType}
                                                    value={DayType.filter(requestoptions => requestoptions.label === watch('day_type'))}
                                                    name='day_type'
                                                    defaultValue={watch('day_type')}
                                                    isClearable="true"
                                                    onChange={(selectedOption) => setValue('day_type', selectedOption?.value || "")}
                                                    styles={{
                                                        menu: provided => ({ ...provided, zIndex: 9999 })
                                                    }}

                                                />

                                            </Grid>




                                        </Grid>
                                        <Grid container spacing={1} mt={2}>
                                            <DateRangePickerComp
                                                selectedDateRange={selectedDateRange}
                                                onDateRangeChange={handleDateRangeChange}
                                            />

                                        </Grid>
                                        <LeaveFile selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} text={"Attach File"} />
                                        <Grid item xs={12} mt={2}>
                                            <TextInput control={control}
                                                name="reason"
                                                isMultiline
                                                label="Reason for Leave"
                                                row={4}
                                                value={watch('reason')}
                                            />
                                        </Grid>



                                        <Grid item xs={12} mt={2}>
                                            <ErrorMessage
                                                errors={errors}
                                                name="multipleErrorInput"
                                                render={({ messages }) =>
                                                    messages &&
                                                    Object.entries(messages).map(([type, message]) => (
                                                        <p key={type}>{message}</p>
                                                    ))
                                                }
                                            />


                                        </Grid>
                                    </Grid>



                                </Grid>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color='error' variant='outlined'>Close</Button>
                                <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                    variant="outlined" color='success' type="submit">{formButtonStatus.label}</LoadingButton>

                            </DialogActions>
                        </form>



                    </>
                )
                }
            </Dialog >
        </div >

    );
};

export default Create;