import { useEffect, useState } from 'react';
import { Task } from '../../../../../api/Endpoints/Tasks';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography } from '@mui/material';
import { DotSpinner } from '@uiball/loaders';

const Tasks = ({ employeeID }) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchTasks = async () => {
    let data = { assigned_to: employeeID };
    setLoading(true);
    try {
      const res = await Task.get(data);
      setTasks(res.data?.data?.data || []);
    } catch (error) {
      
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTasks();
  }, [employeeID]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
        <DotSpinner size={47} speed={0.9} color="rgb(63,197,149)" />
      </Box>
    );
  }

  return (
    <div>
      {tasks.length === 0 ? (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
          <Typography variant="h6" color="textSecondary">
            No tasks available
          </Typography>
        </Box>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            mt: 2,
            boxShadow: 3,
            borderRadius: 2,
            overflow: 'hidden',
          }}
        >
          <Table sx={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Project Title</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Task Title</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Assigned To</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Assigned By</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Reviewer</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Due Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((task) => (
                <TableRow
                  key={task.id}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#f5f5f5',
                    },
                    transition: 'background-color 0.3s ease',
                  }}
                >
                  <TableCell sx={{ padding: '16px' }}>{task.project?.name}</TableCell>
                  <TableCell sx={{ padding: '16px' }}>{task.title}</TableCell>
                  <TableCell sx={{ padding: '16px' }}>{task.assigned_to_user?.name}</TableCell>
                  <TableCell sx={{ padding: '16px' }}>{task.assigned_by_user?.name}</TableCell>
                  <TableCell sx={{ padding: '16px' }}>{task.reviewer?.name}</TableCell>
                  <TableCell sx={{ padding: '16px' }}>{task.status}</TableCell>
                  <TableCell sx={{ padding: '16px' }}>{task.due_date}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default Tasks;
