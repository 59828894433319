import React from 'react';
import { Box, Button, Grid, Typography } from "@mui/material";
import { RefreshOutlined } from "@mui/icons-material";

const PageHeader = (props) => {
    return (
        <Grid>
            <Box>
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        pl: 2 // Adding left padding to prevent overlap with sidebar
                    }}
                >
                    <Typography
                        color="textPrimary"
                        variant="h4"
                        sx={{ pl: 1 }} // Adding additional padding to the title text
                    >
                        {props.title} {props.total > 0 && <span style={{ color: 'grey', fontSize: 20 }}>({props.total})</span>}
                        {props.refresh &&
                            <Button onClick={() => props.onRefresh()}><RefreshOutlined /></Button>
                        }
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />

                </Box>
            </Box>
        </Grid>
    );
};

export default PageHeader;
