import { Dialog, DialogContent, IconButton } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
const ImageView = ({ imageUrl, ZoomView, EditID, setZoomView }) => {

    


    const onClose = () => {
        setZoomView(false)
    }
    return (
        <Dialog open={ZoomView ? ZoomView : true} onClose={onClose} maxWidth="lg">
            <IconButton
                edge="end"
                color="inherit"
                onClick={onClose}
                aria-label="close"
                sx={{ position: 'absolute', top: 0, right: 0 }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <img src={`https://dashboard.omnisellcrm.com/${imageUrl}`} alt="Zoomed View" style={{ width: '80%', height: 'auto' }} />
            </DialogContent>
        </Dialog>

    );
};

export default ImageView;
