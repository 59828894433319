import {
    Backdrop,
    Button,
    Card,
    CircularProgress,
    Typography,
    Grid,
    Box
} from "@mui/material";
import PageHeader from "../../common/page-header";
import { Fragment, useCallback, useState } from "react";
import { Employee } from "../../../../api/Endpoints/Employee";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "../../../../Custom.css";
import EditIcon from "@mui/icons-material/Edit";
import ReactSelector from "react-select";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Waveform } from "@uiball/loaders";
import { toast } from "react-toastify";
import {
    fetchEmployeeRoles,
    fetchDepartments,
    fetchEmployees
} from "../../../CommonFunction/Index";
import SelectX from "../../../form/SelectX";
import SearchField from "../../../../components/searchEntry/Index";
import moment from "moment";
import { Security } from "@mui/icons-material";
import { debounce } from "../../../../components/Debouncer";
import { Departments } from "../../../../api/Endpoints/Departments";
import FeedbackCreate from "./create";

const scheme = yup.object().shape({});

const PeerFeedBack = () => {
    const columns = [

        { field: "comment", headerName: "Comment", width: 250 },
        { field: "provided_by", headerName: "Provided By", width: 150 },
        { field: "provided_for", headerName: "Provided For", width: 150 },

        {
            field: "created_at",
            headerName: "Created at",
            width: 140,
            renderCell: (params) => (
                <>
                    {" "}
                    {params.row.created_at
                        ? moment(params.row.created_at).format("DD-MM-YYYY")
                        : "-"}
                </>
            )
        },

        {
            field: "edit",
            headerName: "Edit",

            width: 100,
            renderCell: (params) => (
                <>
                    <Button
                        size="small"
                        onClick={() => handleEdit(params.row.id)}
                    >
                        <EditIcon style={{ color: "gray", height: 18 }} />
                    </Button>
                </>
            )
        },


    ];

    const { watch, setValue, control } = useForm({});
    const [refresh, setRefresh] = useState(false);
    const [editId, setEditId] = useState();

    const [pageNumber, setPageNumber] = useState(0);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState();
    const [TableUpdate, setTableUpdate] = useState(false);

    const handleCreate = () => {
        setEditId(0);
        setRefresh(Math.random);
    };
    const handleEdit = (id) => {
        setEditId(parseInt(id));
    };


    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    const debouncedSearch = useCallback(
        debounce((value) => {
            setSearchKeyword(value);
            setTableUpdate(true);
            setPageNumber(0);
        }, 300),
        []
    );

    const [searchValue, setSearchValue] = useState("");
    const [searchLoading, setsearchLoading] = useState(false)
    const handleSearchEntry = (event) => {
        const value = event.target.value;
        setSearchValue(value);
        setsearchLoading(true)
        debouncedSearch(value);
    };

    const clearSerachInput = () => {
        setTableUpdate(true)
        setsearchLoading(true)
        setPageNumber(0);
        setSearchValue("");
        setSearchKeyword();
    };

    useEffect(() => {
        fetchTable();
    }, [
        pageNumber,
        searchKeyword,
        watch("employee_role"),
        watch("employee_type"),
    ]);

    //fetch Data with Retry Count

    const fetchTable = (retryCount = 0) => {
        // const maxRetries = 3;
        if (!TableUpdate) {
            setLoading(true);
        }

        Departments.get({
            keyword: searchKeyword,
            page: pageNumber,
            limit: 50,
            employee_role: watch("employee_role")?.id,
            employee_level: watch("employee_level"),
            employee_type: watch("employee_type"),
            department: watch("department")?.name
        })
            .then((response) => {
                setList(response.data.data);
                


                setLoading(false);
                setsearchLoading(false)
            })
            .catch((error) => {
                // if (retryCount < maxRetries) {
                //   console.error(error);
                //   fetchTable(retryCount + 1);
                // } else {
                setLoading(false);
                toast.error(
                    "An error occurred. Please try again later or contact the administrator.",
                    { autoClose: 6000 }
                );
                // }
            })
            .finally(() => {
                if (TableUpdate) {
                    setTableUpdate(false);
                }
            });
    };


    return (

        <Fragment>
            <Card
                sx={{
                    p: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                    mt: 1,
                    ml: 2,
                    boxShadow: 0,
                    backgroundColor: "transparent"
                }}

                className="page_header"
            >
                <PageHeader title={"Peer FeedBack"} total={list.total} />
                <FeedbackCreate key={refresh} onNew={handleCreate} onUpdate={fetchTable} editId={editId} setEditId={setEditId} />


                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={searchLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Card>
            {/* if table update is false and loading is true show the loaidng screen */}
            {!TableUpdate && loading ? (
                <Fragment>
                    <Box id="ui-ball-loader">
                        <Waveform
                            size={50}
                            lineWeight={3.5}
                            speed={1}
                            color="rgb(63,197,149"
                        />
                    </Box>
                </Fragment>
            ) : (
                <Fragment>
                    <Card sx={{ m: 2 }} variant="outlined">
                        <Grid
                            display={"flex"}
                            justifyContent={"space-between"}
                            pt={1}
                            xs={12}
                            sm={12}
                        >
                            <SearchField
                                clearSerachInput={clearSerachInput}
                                serachEntry={searchValue}
                                handleSearchEntry={handleSearchEntry}
                            />
                            <Grid display={"flex"}>

                                <Grid mr={1} sx={{ width: 200 }}>
                                    <SelectX
                                        placeholder={"Provided By"}
                                        options={fetchEmployees}
                                        control={control}
                                        name={"provided_by"}
                                    />
                                </Grid>
                                <Grid mr={1} sx={{ width: 200 }}>
                                    <SelectX
                                        placeholder={"Provided To"}
                                        options={fetchEmployees}
                                        control={control}
                                        name={"provided_to"}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
                        {list?.data?.length > 0 ? (
                            <Fragment>
                                {typeof list.data === "object" && (
                                    <DataGrid
                                        paginationMode="server"
                                        rows={list?.data}
                                        columns={columns}
                                        page={pageNumber}
                                        pageSize={50}
                                        rowsPerPageOptions={[50]}
                                        rowCount={list.total}
                                        onPageChange={handlePageChange}
                                        autoHeight
                                        density={"comfortable"}
                                        getRowHeight={() => 35}
                                        sx={{ ml: 1 }}
                                    />
                                )}
                            </Fragment>
                        ) : (
                            <Box id="data-not-found-msg">
                                <Typography alignSelf={"center"}>
                                    Employee not found.
                                </Typography>
                            </Box>
                        )}
                    </Card>
                </Fragment>
            )}
        </Fragment>

    );
};

export default PeerFeedBack;
