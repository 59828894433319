import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
// import "../../../../../../../Custom.css"
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Card,
    CardContent,
    Typography,
    Grid,
    Divider,
    Select,
    MenuItem,
    DialogTitle,
    InputLabel,
    TextareaAutosize,
    TextField,
    Slide,
    CircularProgress,
    Backdrop,
    Box,
} from "@mui/material";
import { useEffect } from 'react';
import { useState } from 'react';
import { LeaveApplications } from '../../../../../../../api/Endpoints/LeaveApplication';

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        border: '1px solid #ccc ',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}));

const Details = ({ ID }) => {
    const classes = useStyles();


    const [Loading, setLoading] = useState(false)
    const [Data, setData] = useState()
   
    useEffect(() => {
        fetchDetails()
    }, [ID])

    const fetchDetails = async () => {
        
        setLoading(true);
        let DepartmentDetails = await LeaveApplications.getDetails({ id: ID });
        let data = DepartmentDetails.data.data
        setData(data)
        setLoading(false);
    }
    
    return (
        <>
            {Loading ? (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '30vh'
                }}>
                    <CircularProgress className='ld-circle' />
                </Box >
            ) : (
                <>
                    <Card className={classes.card}>
                        <CardContent>
                            {/* <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Name:

                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.name}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid> */}


                            
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Employee Id:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.description}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>

                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Date:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.description}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>


                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Leave Type:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.description}
                                        </Typography>
                                    </Typography>
                                </Grid>

                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Approved by:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.description}
                                        </Typography>
                                    </Typography>
                                </Grid>
                                

                            </Grid>

                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Approval Sataus:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.description}
                                        </Typography>
                                    </Typography>
                                </Grid>
                                

                            </Grid>
                            
                        </CardContent>
                    </Card>
                </>
            )
            }
        </>
    );
};

export default Details;