import { Grid, TextField, Button, Typography, Box, CircularProgress, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NotesAPI } from '../../../../../api/Endpoints/Notes';
import { toast } from 'react-toastify';
import NoteCard from '../../../../../components/card/Notes';
import { useStateContext } from '../../../../../contexts/SignIn/UsersID/usersID';
import { LoadingButton } from '@mui/lab';
import { NoteAdd } from '@mui/icons-material';


function Notes() {
    const [list, setList] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [formData, setFormData] = useState(null)
    const [loading, setLoading] = useState(false);
    const { userId } = useStateContext();
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });
    useEffect(() => {
        fetchNotes();
    }, []);

    const fetchNotes = () => {
        setLoading(true);
        NotesAPI.get({ page: Number(pageNumber) + 1, users_id: userId }).then(response => {
            setList(response.data.data.data);
            setLoading(false);
        });
    };


    const onSubmit = async () => {

        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
        let dataToSubmit = {
            users_id: userId,
            note: formData
        }
        let response = await NotesAPI.add(dataToSubmit)
        if (response.data.message === 'New note has been successfully saved.') {
            toast.success('New note has been successfully saved', { autoClose: 1000 });
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
            fetchNotes()
        } else {
            setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        }
        setFormData('')
    }
    return (
        <div>


            <Grid container alignItems="center" sx={{ p: 2 }} spacing={2}>
                <Grid item xs={8}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <TextField
                            fullWidth
                            label="Add Note"
                            size="small"
                            multiline
                            rows={3}
                            required
                            value={formData}
                            onChange={(e) => setFormData(e.target.value)}
                            sx={{
                                flex: 1,
                                '& .MuiInputBase-root': {
                                    borderRadius: 2, // Smooth rounded edges
                                },
                            }}
                        />

                        <LoadingButton
                            onClick={onSubmit}
                            loading={formButtonStatus.loading}
                            disabled={formButtonStatus.disabled}
                            variant="contained"
                            color="success"
                            sx={{
                                minWidth: 120, // Consistent button width
                                borderRadius: 2, // Rounded button edges
                                boxShadow: 1, // Subtle shadow for depth
                            }}
                        >
                            {formButtonStatus.label}
                        </LoadingButton>
                    </Stack>
                </Grid>
            </Grid>


            {loading ?
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                    <CircularProgress className='ld-circle' size={33} />
                    <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                        Loading...
                    </Typography>
                </Box> : (
                    <Box sx={{ mt: 3 }}>
                        {list?.length > 0 ? (
                            <Grid container spacing={2}>
                                {list.map((note) => (
                                    <Grid item xs={12} sm={6} md={4} key={note.id}>
                                        <NoteCard note={note} fetchNotes={fetchNotes} />
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Box
                                sx={{
                                    height: 320,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    color: "gray",
                                    textAlign: "center",
                                }}
                            >
                                <NoteAdd sx={{ fontSize: 48, mb: 1, color: "#b0b0b0" }} />
                                <Typography variant="h6" fontWeight={500}>
                                    No notes found
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Start by adding a new note.
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )
            }
        </div >
    );
}

export default Notes;

