import React from 'react';
import { FormControl, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";

const SelectX = (props) => {
    const { type, options, label, control, name, ismultiple, defaultValue, error, error2, helperText } = props;

    const onInputChange = (e) => {
        options(e);
    };

    const isReadOnly = type === "read-only";
    return (
        <FormControl sx={{ width: '100%' }}>
            <label className={"css-1wjmx8d-MuiFormLabel-root-MuiInputLabel-root"}>{label}</label>
            <Controller

                control={control}
                name={name}
                render={({ field }) => (
                    <AsyncSelect
                        {...field}
                        isClearable={true}
                        isMulti={ismultiple}
                        defaultOptions

                        cacheOptions={false}
                        loadOptions={options}
                        getOptionLabel={e =>
                            e.account_name || e.name || e.category_name||e?.user?.name
                        }
                        getOptionValue={e => e.id}
                        onInputChange={onInputChange}
                        menuPortalTarget={document.body}
                        styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),


                        }}
                        placeholder={props.placeholder}
                        defaultValue={defaultValue}
                        isDisabled={isReadOnly} // Set the disable state based on the 'type' prop

                    />
                )}
            />
            {error && <Typography variant={"string"} sx={{ color: "#ec4c47", fontSize: '0.75rem' }}>{error.message}</Typography>}
            {error2 && <Typography variant={"string"} sx={{ color: "#ec4c47", fontSize: '0.75rem', mt: 1 }}>{error2}</Typography>}
        </FormControl >
    );
};

export default SelectX;
