import React, { useState, useEffect } from "react";
import {
  Modal,
  Grid,
  Card,
  Typography,
  Divider,
  Checkbox,
  Button,
  FormControlLabel,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";
import { Employee } from "../../../../api/Endpoints/Employee";
import { Users } from "../../../../api/Endpoints/Users";
import { useDetailContext } from "../../../../contexts/UserDetail";

const AccessModal = ({ isOpen, setIsOpen, employeeId, setEmployeeId, onUpdate, userId, setUserId }) => {

  const { userDetails } = useDetailContext(); 
  
  const [formButtonStatus, setFormButtonStatus] = useState({
    label: "Submit",
    loading: false,
    disabled: false,
  });

  const { control, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      worksPortal: false,
      hrPortal: false,
      accountsPortal: false,
      clientPortal: false,
      inventoryPortal: false,
      showcasePortal: false,
      superAdmin: false,
      adminPortal: false
    },
  });

  const closeModal = () => {
    setIsOpen(false);
    setEmployeeId()
    setUserId()
    reset();
  };

  const [superAdminAccess, setsuperAdminAccess] = useState(false)
  const [fetchLoading, setfetchLoading] = useState(false)
  const fetchDetails = async () => {
    if (!employeeId) {
      return;
    }

    try {
      setfetchLoading(true)
      const EmployeeDetail = await Employee.getEmployeeDetails({ id: employeeId });
      if (EmployeeDetail.data.status === "success") {
        const data = EmployeeDetail.data.data?.user;
        reset({
          worksPortal: data.has_work_portal_access ? true : false,
          hrPortal: data.has_hr_portal_access ? true : false,
          clientPortal: data.has_client_portal_access ? true : false,
          inventoryPortal: data.has_inventory_portal_access ? true : false,
          showcasePortal: data.has_showcase_portal_access ? true : false,
          superAdmin: data.has_super_admin_access ? true : false,
          accountsPortal: data.has_accounts_portal_access ? true : false,
          adminportal: data.has_admin_portal_access ? true : false,
        });
      } else {
        toast.error("Error fetching employee details.");
      }
      setfetchLoading(false)
    } catch (error) {
      toast.error("Failed to fetch employee details.");
      setfetchLoading(false)
    }
  };

  // Submit 
  const onSubmit = async (data) => {
    const transformedData = {
      id: userId,
      has_work_portal_access: data.worksPortal ? 1 : 0,
      has_hr_portal_access: data.hrPortal ? 1 : 0,
      has_client_portal_access: data.clientPortal ? 1 : 0,
      has_inventory_portal_access: data.inventoryPortal ? 1 : 0,
      has_super_admin_access: data.superAdmin ? 1 : 0,
      has_accounts_portal_access: data.accountsPortal ? 1 : 0,
      has_admin_portal_access: data.adminportal ? 1 : 0,
      has_showcase_portal_access: data.showcasePortal ? 1 : 0,
    };

    try {
      setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });

      let action;
      action = await Employee.permission(transformedData);
      if (action?.data?.status != 'error') {
        onUpdate();
        closeModal()
        toast.success(action?.data?.message)
        setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
      } else {
        setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        toast.error(action?.data?.message)
      }
    } catch (error) {
      console.log(error)
      setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
      toast.error("You are not authorized for this operation")
    }

  };

  useEffect(() => {
    if (isOpen && employeeId) {
      fetchDetails();
    }

  }, [isOpen, employeeId]);

  useEffect(() => {
    if(userDetails?.has_super_admin_access){
      setsuperAdminAccess(true)
    }
  }, [userDetails])
  

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh", padding: 20 }}
      >
        <Card
          sx={{
            p: 4,
            width: "60%",
            maxWidth: "800px",
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          <Typography variant="h5" gutterBottom>
            Access & Permissions
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <form onSubmit={handleSubmit(onSubmit)}>
            {
              fetchLoading ?
                'Loading...'
                :

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Controller
                      name="worksPortal"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          disabled={!superAdminAccess}
                          control={<Checkbox {...field} checked={field.value} />}
                          label="Works Portal"

                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="clientPortal"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          disabled={!superAdminAccess}
                          control={<Checkbox {...field} checked={field.value} />}
                          label="Client Portal"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="hrPortal"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          disabled={!superAdminAccess}
                          control={<Checkbox {...field} checked={field.value} />}
                          label="HR Portal"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="inventoryPortal"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          disabled={!superAdminAccess}
                          control={<Checkbox {...field} checked={field.value} />}
                          label="Inventory Portal"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="accountsPortal"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          disabled={!superAdminAccess}
                          control={<Checkbox {...field} checked={field.value} />}
                          label="Accounts Portal"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="showcasePortal"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          disabled={!superAdminAccess}
                          control={<Checkbox {...field} checked={field.value} />}
                          label="Showcase Portal"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="adminportal"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          disabled={!superAdminAccess}
                          control={<Checkbox {...field} checked={field.value} />}
                          label="Admin Portal"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      name="superAdmin"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          disabled={!superAdminAccess}
                          control={<Checkbox {...field} checked={field.value} />}
                          label="Super Admin"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
            }

            <Grid container justifyContent="flex-end" spacing={2} sx={{ mt: 3 }}>
              {
                superAdminAccess && !fetchLoading &&
                <Grid item>
                  <Button disabled={formButtonStatus.disabled} variant="contained" color="primary" type="submit">
                    {formButtonStatus.loading ? "Saving..." : "Save"}
                  </Button>
                </Grid>
              }
              <Grid item>
                <Button variant="contained" color="error" onClick={closeModal}>
                  Close
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Grid>
    </Modal>
  );
};

export default AccessModal;
