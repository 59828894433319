import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card, Grid, Typography, Divider, IconButton, Stack } from '@mui/material';
import Delete from '../Popups/Delete';
import { useState } from 'react';
import EditPopup from '../Popups/EditNotes';
import { NotesAPI } from '../../api/Endpoints/Notes';
import { toast } from 'react-toastify';
import moment from 'moment';

const NoteCard = ({ note, fetchNotes }) => {
    const [showDeletePopup, setDeletePopup] = useState(false);
    const [showEditpopup, setEditpopup] = useState(false)
    const [ID, setID] = useState(0);
    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Save",
        loading: false,
        disabled: false,
    });
    const [NoteData, setData] = useState({
        ID: 0,
        note: ''
    })
    const handleDelete = (id) => {
        setID(id);
        setDeletePopup(true);
    };

    const handleEdit = (note) => {
        setEditpopup(true)
        setData({
            ID: note.id,
            note: note.note
        })
    }

    const onSave = async () => {
        setFormButtonStatus({ ...formButtonStatus, loading: true, disabled: true });
        let dataToSubmit = {
            id: NoteData.ID,
            note: NoteData.note
        }
        let response = await NotesAPI.update(dataToSubmit)
        toast.success(response.data.message, { autoClose: 1000 });
        setFormButtonStatus({ ...formButtonStatus, loading: false, disabled: false });
        setID(false)
        setEditpopup(false)
        fetchNotes()
    }

    return (
        <>
            {showDeletePopup && (
                <Delete
                    ID={ID}
                    setID={setID}
                    setDeletePopup={setDeletePopup}
                    showDeletePopup={showDeletePopup}
                    Callfunc={() => fetchNotes()}
                    url="/employees/notes/delete?id="
                    title="Note"
                />
            )}

            {showEditpopup && (
                <EditPopup
                    note={NoteData}
                    editNote={setData}
                    showEditpopup={showEditpopup}
                    setEditpopup={setEditpopup}
                    onSave={onSave}
                    formButtonStatus={formButtonStatus}
                />
            )

            }
            <Card
                sx={{
                    p: 1,
                    m: 1,
                    // width: '60%',
                    borderRadius: 2,
                    boxShadow: 3, // Subtle shadow for depth
                    backgroundColor: '#f9f9f9', // Light background for contrast
                }}
            >
                {/* Date & Added By */}
                <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                    <Typography sx={{ fontSize: '0.75rem', color: 'gray' }}>
                        {moment(note?.created_at).format('h:m A DD-MM-YYYY')} {/* Formats date & time */}
                    </Typography>

                    <Typography sx={{ fontSize: '0.75rem', color: 'gray', fontStyle: 'italic' }}>
                        Added by: {note.created_user?.name || 'Unknown'}
                    </Typography>
                </Grid>

                {/* Note Content */}
                <Typography
                    sx={{
                        wordBreak: 'break-word',
                        color: '#333',
                        fontSize: '1rem',
                    }}
                    dangerouslySetInnerHTML={{
                        __html: note.note.replace(/\n/g, '<br />'),
                    }}
                />

                <Divider sx={{ mt: 2 }} />

                {/* Action Buttons */}
                <Grid container justifyContent="flex-end" alignItems="center" sx={{ mt: 0.5 }}>
                    <Stack direction="row" spacing={0.5}>
                        <IconButton onClick={() => handleEdit(note)} sx={{ color: 'gray', p: 0.5 }}>
                            <EditIcon fontSize="small" />
                        </IconButton>

                        <IconButton onClick={() => handleDelete(note.id)} sx={{ color: 'red', p: 0.5 }}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Stack>
                </Grid>
            </Card>



        </>
    );
};

export default NoteCard;
