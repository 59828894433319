import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Employee } from '../../../../../api/Endpoints/Employee';
import moment from 'moment';
import AddIcon from "@mui/icons-material/Add";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import TextInput from '../../../../form/TextInput'
import { useForm } from "react-hook-form";
import * as yup from "yup";
import '../../../.././../Custom.css';
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { ErrorMessage } from "@hookform/error-message";
import { Documentcategory } from '../../../../../api/Endpoints/Documentcategory';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Card,
    CardContent,
    Typography,
    Grid,
    Box,
    Divider,
    Select,
    MenuItem,
    DialogTitle,
    InputLabel,
    TextareaAutosize,
    TextField,
    Slide,
    CircularProgress,
    Backdrop,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
} from "@mui/material";
import { useEffect } from 'react';
import { useState } from 'react';
import DateInput from '../../../../form/DateInput';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const scheme = yup.object().shape({
    basic_salary: yup.string().required(),
    remarks: yup.string(),

});


const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        boxShadow: '1px 2px 2px 2px rgba(0, 0, 0, 0.3)',
    },
    title: {
        fontWeight: 'bold',
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
    select: {
        minWidth: 200,
    },
}));




const Salary = ({ ID }, props) => {
    const { register, handleSubmit, watch, formState: { errors }, control, setValue, getValues, reset } = useForm({ resolver: yupResolver(scheme), criteriaMode: "all" });
    const classes = useStyles();
    const [Data, setData] = useState(null);
    const [open, setOpen] = useState(false);
    const [Loading, setLoading] = useState(false)
    // const [Data, setData] = useState()
    console.log(Data)

    useEffect(() => {
        fetchDetails()
    }, [])

    const onSubmit = (data) => {
        setFormButtonStatus({ ...formButtonStatus, loading: true });

        let dataToSubmit = {
            id: props?.editId,
            users_id: props?.editId,
            basic_salary: data.name,
            remarks: data.remarks,


        };



        let action;
        // if (props?.editId > 0) {

        //     
        //     action = Documentcategory.update(dataToSubmit);

        // } else {
        //     
        //     action = Documentcategory.add(dataToSubmit);

        // }

        action.then(response => {
            setFormButtonStatus({ label: "Submitted", loading: false, disabled: true });
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            if (response.data.errors) {
                let err = response.data.errors
                Object.keys(err).forEach((key) => {
                    toast.error(err[key].name);
                });

                return
            }

            toast.success(response.data.message)

            // props.onUpdate();
            setOpen(false)
        }).catch(errors => {
            toast.error("Internal server error");
            setFormButtonStatus({ label: "Create", loading: false, disabled: false });
            setOpen(false)
        })
    }






    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const [formButtonStatus, setFormButtonStatus] = useState({
        label: "Submit",
        loading: false,
        disabled: false,
    });

    const rows = [
        {
            id: 1, col1: '100000', col2: '3.44&', col3: '4.55%', col4: '5.53%', col5: '1000', col6: '2000', col7: 'Data 7',
            col7: '1000', col8: '22-2-2023', col9: 'ashfak'
        },
        // Add more rows as needed
    ];
    const fetchDetails = async () => {
        setLoading(true);
        setData()
        let EmployeeDetail = await Employee.getEmployeeDetails({ id: ID });
        let data = EmployeeDetail.data.data
        setData(data)
        setLoading(false);
    }

    return (
        <>
            <Box width={'100%'} display={'flex'} justifyContent={'end'}>

                <Button
                    className="create-btn"
                    variant="contained"
                    onClick={handleClickOpen}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    Edit
                </Button>
            </Box>
            <Dialog open={open}

                PaperProps={{ sx: { width: "40%", height: "100%", position: "fixed", right: 0, top: 0, bottom: 0, m: 0, p: 0, borderRadius: 0, maxHeight: '100%' } }}
                aria-describedby="alert-dialog-slide-description"
                TransitionComponent={Transition}
                onClose={handleClose}>
                <DialogTitle>Edit Salary</DialogTitle>

                {Loading ? (
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '30vh'
                    }}>
                        <CircularProgress className='ld-circle' />
                    </Box >) : (
                    <>
                        <DialogContent sx={{ p: 2, mt: 3 }}>
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <TextInput control={control} name="name"
                                                label="Basic Salary"
                                                value={watch('basic_salary')} />
                                        </Grid>

                                        <Grid item xs={6}  >
                                            <TextInput control={control} name="tds_percentage"
                                                label="TDS Percentage"
                                                value={watch('tds_percentage')}

                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} mt={2}>

                                        <Grid item xs={6}  >
                                            <TextInput control={control} name="" esi_employee
                                                label=" ESI Employee Share"
                                                value={watch('esi_employee')}

                                            />
                                        </Grid>
                                        <Grid item xs={6}  >
                                            <TextInput control={control} name="pf_employee"
                                                label="PF Employer Share"
                                                value={watch('pf_employee')}

                                            />
                                        </Grid>
                                    </Grid >

                                    <Grid container spacing={1} mt={2}>

                                        <Grid item xs={6}  >
                                            <TextInput control={control} name="hra"
                                                label="HRA:"
                                                value={watch('hra')}

                                            />
                                        </Grid>
                                        <Grid item xs={6}  >
                                            <TextInput control={control} name="travel_allowance"
                                                label=" Travel Allowance"
                                                value={watch('travel_allowance')}

                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} mt={2}>

                                        <Grid item xs={6}  >
                                            <TextInput control={control} name="remarks"
                                                label="Other Allowance"
                                                value={watch('other_allowance')}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <DateInput
                                                control={control}
                                                name="effective_date"
                                                label="Effective Date"
                                                value={watch('effective_date')}
                                            />
                                        </Grid>
                                    </Grid >
                                    <Grid item xs={12} spacing={1} mt={2}>
                                        <TextInput
                                            control={control}
                                            name="remarks"
                                            isMultiline
                                            label="Remarks"
                                            row={3}
                                            value={watch('remarks')} />
                                    </Grid>

                                    <Grid sx={{ p: 1 }} item xs={12} mt={2}>
                                        <ErrorMessage
                                            errors={errors}
                                            name="multipleErrorInput"
                                            render={({ messages }) =>
                                                messages &&
                                                Object.entries(messages).map(([type, message]) => (
                                                    <p key={type}>{message}</p>
                                                ))
                                            }
                                        />

                                        <LoadingButton loading={formButtonStatus.loading} disabled={formButtonStatus.disabled}
                                            variant="outlined" color='success' type="submit">{formButtonStatus.label}</LoadingButton>
                                    </Grid>

                                </Grid>

                            </form>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>

            {Loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                    <CircularProgress className='ld-circle' size={33} />
                    <Typography variant="body1" sx={{ marginLeft: 1, fontWeight: 500 }}>
                        Loading...
                    </Typography>
                </Box>
            ) : (
                <>
                    <Card className={classes.card}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Basic Salary:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.basic_salary}
                                        </Typography>
                                    </Typography>


                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        TDS Percentage:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.tds_percentage}
                                        </Typography>
                                    </Typography>

                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        ESI Employee Share:    <Typography variant="body2" style={{ display: 'inline', paddingTop: 10, fontSize: '16px' }}>
                                            {Data?.esi_employee}
                                        </Typography>
                                    </Typography>


                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        PF Employer Share:<Typography variant="body2" style={{ display: 'inline', paddingTop: 10, fontSize: '16px' }}>
                                            {Data?.pf_employee}
                                        </Typography>
                                    </Typography>

                                </Grid>

                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        HRA:<Typography variant="body2" style={{ display: 'inline', paddingTop: 10, fontSize: '16px' }}>
                                        </Typography>

                                    </Typography>

                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Travel Allowance:   <Typography variant="body2" style={{ display: 'inline', paddingTop: 10, fontSize: '16px' }}>
                                            {Data?.travel_allowance}
                                        </Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={6}>
                                    <Typography variant="body1" component="h2" style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                                        Other Allowance:
                                        <Typography variant="body2" style={{ display: 'inline', paddingLeft: 10, fontSize: '16px' }}>
                                            {Data?.other_allowance}
                                        </Typography>
                                    </Typography>

                                </Grid>


                            </Grid>
                            <Divider className={classes.divider} />





                        </CardContent>
                    </Card>




                    <TableContainer component={Paper} sx={{ mt: 7 }}>
                        <Typography component={'h1'} fontSize={22} m={2} fontWeight={'bold'}>History</Typography>
                        <Table sx={{ mt: 2 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Basic Salary</TableCell>
                                    <TableCell>
                                        TDS Percentage</TableCell>
                                    <TableCell>ESI Employee Share</TableCell>
                                    <TableCell>ESI Employee Share</TableCell>
                                    <TableCell>HRA</TableCell>
                                    <TableCell>
                                        Travel Allowance</TableCell>
                                    <TableCell>Other Allowance:</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Updated By</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.col1}</TableCell>
                                        <TableCell>{row.col2}</TableCell>
                                        <TableCell>{row.col3}</TableCell>
                                        <TableCell>{row.col4}</TableCell>
                                        <TableCell>{row.col5}</TableCell>
                                        <TableCell>{row.col6}</TableCell>
                                        <TableCell>{row.col7}</TableCell>
                                        <TableCell>{row.col8}</TableCell>
                                        <TableCell>{row.col9}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )
            }
        </>
    );
};

export default Salary;
